import { isSavingLocation } from "store/Location/locationDetails.slice"
import { AntdInput } from "components"
import { useAppSelector } from "hooks"
import AntdModal from "components/AntdModal"
interface Props {
	showInputs: boolean
	inputSize: number[]
	openModal: boolean
	handleModal: (value: boolean) => void
	name: string[]
	handleInput: (value: string, index: number) => void
	submitHandler: () => void
}

const QRModal: React.FC<Props> = ({
	inputSize,
	handleModal,
	openModal,
	name,
	handleInput,
	submitHandler,
	showInputs,
}) => {
	const locationSaving = useAppSelector(isSavingLocation())

	return (
		<AntdModal
			open={openModal}
			onCancel={() => handleModal(false)}
			okText="Save"
			okButtonProps={{ loading: locationSaving, disabled: locationSaving }}
			onOk={submitHandler}
			title="QR Code"
			width={400}
		>
			{showInputs ? (
				<>
					<div className="grid grid-cols-2 gap-4">
						{inputSize.map((count, index) => (
							<div key={index}>
								<AntdInput
									key={index}
									label={`Name ${count + 1}`}
									type="text"
									value={name[index]}
									onChange={e => handleInput(e.target.value, index)}
								/>
							</div>
						))}
					</div>
				</>
			) : (
				<></>
			)}
		</AntdModal>
	)
}

export default QRModal
