import React, { useState, useEffect } from "react"
import { AntdButton, ImageField, TextArea } from "components"
import { clearMedia, selectLoadingMedia, selectMediaURL, uploadMedia } from "store/Media/media.slice"
import useAppDispatch from "hooks/useAppDispatch"
import useAppSelector from "hooks/useAppSelector"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { updateShiftModalSchema } from "Schema/workMonitor/workMonitorSchema"
import { UpdateWorkMonitor } from "@type/workMonitor.types"
import AntdModal from "components/AntdModal"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	confirmationText?: string
	handleSubmit?: (data: UpdateWorkMonitor) => void
	confirmationAction?: boolean
	confirmationLoading?: boolean
}

const ShiftStatusModal: React.FC<Props> = ({
	open,
	modalHandler,
	confirmationText = "Accept",
	handleSubmit = () => undefined,
	confirmationAction = false,
	confirmationLoading = false,
}) => {
	const dispatch = useAppDispatch()
	const [comment, setComment] = useState<string>("")
	const [userImage, setUserImage] = useState<string[]>([])
	const imageURL = useAppSelector(selectMediaURL())
	const isImageLoading = useAppSelector(selectLoadingMedia())

	const {
		setError,
		clearErrors,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(updateShiftModalSchema),
		mode: "onChange",
	})

	useEffect(() => {
		if (imageURL.length) {
			setUserImage(prevState => [...prevState, ...imageURL])
			dispatch(clearMedia())
		}
	}, [imageURL])

	useEffect(() => {
		setComment("")
		setUserImage([])
		reset()
	}, [open])

	const handleDeleteImage = (imageUrl: string) => {
		setUserImage(prevState => prevState.filter(url => url !== imageUrl))
	}

	return (
		<AntdModal
			open={open}
			onCancel={() => modalHandler(false)}
			title="Status"
			width={500}
			okText={confirmationText}
			onOk={() => handleSubmit({ comment, images: userImage })}
			okButtonProps={{ loading: confirmationLoading, disabled: confirmationAction }}
		>
			<div className="justify-center sm:flex sm:items-start">
				<div className="w-full">
					<p className="text-6">Status</p>
					<div className="flex flex-col pb-2.5">
						<TextArea
							label="Comments"
							value={comment}
							onChange={({ target: { value } }) => setComment(value)}
						/>
					</div>
					<ImageField
						name="thumbnail"
						containerClass="h-16 w-32"
						loading={isImageLoading}
						image={userImage}
						setError={setError}
						errors={errors}
						uploadMultiple={true}
						clearErrors={clearErrors}
						onImageChange={file => {
							if (!errors["thumbnail"]?.message.length) dispatch(uploadMedia(file))
						}}
						handleDeleteImage={handleDeleteImage}
					/>

					<div className="flex justify-end space-x-2">
						<AntdButton onClick={() => modalHandler(false)}>Cancel</AntdButton>
						<AntdButton
							loading={confirmationLoading}
							disabled={confirmationAction}
							onClick={() => handleSubmit({ comment, images: userImage })}
							className="capitalize text-black"
						>
							{confirmationText}
						</AntdButton>
					</div>
				</div>
			</div>
		</AntdModal>
	)
}

export default ShiftStatusModal
