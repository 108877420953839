import { FC } from "react"

interface props {
	phone: number
	className?: string
}

const WhatsAppButton: FC<props> = ({ phone, className }) => {
	const link = `https://wa.me/${phone}`

	return (
		<a
			href={link}
			className={`h-30 w-30 fixed bottom-5 right-10 text-blue-500 ${className}`}
			target="_blank"
			rel="noopener noreferrer"
		>
			<img src="https://img.icons8.com/color/48/000000/whatsapp.png" />
		</a>
	)
}

export default WhatsAppButton
