// REACT PACKAGE
import { EmployeeTypesEnum, defaultPosition } from "config/constants"
import * as Yup from "yup"

export const postInitialSchema = {
	contract: "",
	PBCode: "",
	shortName: "",
	name: "",
	startTime: "06:00",
	finishTime: "18:00",
	telephone1: "",
	telephone2: "",
	telephone3: "",
	displayOrder: "",
	reportCode: "",
	usedForJobs: true,
	siteTrainingRequired: false,
	enabled: true,
	cliBehavior: "Accept",
	geoFencingEnabled: false,
	type: EmployeeTypesEnum.SECURITY,
	fullAddress: "",
	radius: 50,
	latitude: defaultPosition.lat,
	longitude: defaultPosition.lng,
	locationAndInstructions: "",
	officerPayRate: "",
	clientChargeRate: "",
}

export const postValidationSchema = Yup.object().shape(
	{
		contract: Yup.string().required().label("Contract Id"),
		PBCode: Yup.string().nullable().label("PBCode Id"),
		shortName: Yup.string().required().label("Short Name").trim(),
		name: Yup.string().required().label("Name").trim(),
		startTime: Yup.string().required().label("Start Time"),
		finishTime: Yup.string().required().label("Finish Time"),
		telephone1: Yup.string().label("Telephone 1").trim(),
		telephone2: Yup.string().label("Telephone 2").trim(),
		telephone3: Yup.string().label("Telephone 3").trim(),
		displayOrder: Yup.number()
			.label("Display Order")
			.nullable(true)
			.transform((_, val) => (val ? Number(val) : null)),
		// reportCode: Yup.string().label("Report Card"),
		// usedForJobs: Yup.boolean().required().label("Used for Job"),
		siteTrainingRequired: Yup.boolean().required().label("Site Training Required"),
		// enabled: Yup.boolean().required().label("Enabled"),
		// cliBehavior: Yup.string().required().label("Cli Behavior"),
		geoFencingEnabled: Yup.boolean().required().label("Cli Behavior"),
		fullAddress: Yup.string().label("Full Address"),
		radius: Yup.number()
			.min(50)
			.max(9999)
			.nullable(true)
			.label("Radius")
			.transform((_, val) => (val ? Number(val) : null))
			.when("geoFencingEnabled", {
				is: (value: boolean) => !!value,
				then: rule => rule.required("Radius is a required field when Geo fencing is enabled"),
			}),
		latitude: Yup.number()
			.label("Latitude")
			.when("geoFencingEnabled", {
				is: (value: boolean) => !!value,
				then: rule => rule.required("Latitude is a required field when Geo fencing is enabled"),
			}),
		longitude: Yup.number()
			.label("Longitude")
			.when("geoFencingEnabled", {
				is: (value: boolean) => !!value,
				then: rule => rule.required("Longitude is a required field when Geo fencing is enabled"),
			}),
		locationAndInstructions: Yup.string().label("Location and Instructions").trim(),
		clientChargeRate: Yup.number()
			.positive()
			.transform(value => (isNaN(value) ? undefined : value))
			.label("Client Charge Rate"),
		officerPayRate: Yup.number()
			.positive()
			.transform(value => (isNaN(value) ? undefined : value))
			.label("Officer Pay Rate"),
		type: Yup.string().required().label("Type"),
	},
	[
		["longitude", "longitude"],
		["latitude", "latitude"],
		["radius", "radius"],
	],
)
