import { useAppSelector, useAppDispatch } from "hooks"
import {
	getEmployeesInformationByLocation,
	isEmployeeInformationLoading,
	selectEmployeeInformationList,
} from "store/Employee/Information.slice"
import { Icon, Table2 } from "components"
import { useContext, useEffect, useMemo, useState } from "react"
import EmployeeRetainContext, { EmployeeRetainContextProps } from "pages/schedules/Location/CreateScheduleRetainContext"
import { EmployeeTypesEnum } from "config/constants"
import { meterToMiles } from "../config/utils"
import AntdModal from "./AntdModal"

interface Props {
	open: boolean
	locationId: string
	data: { startTime: string; finishTime: string }[] | undefined
	reset: () => void
	modalHandler: (open: boolean) => void
	rowSelectHandler: (employeeId: string) => void
	filterEmployeeType: EmployeeTypesEnum | ""
}
const EmployeeInformationModal: React.FC<Props> = ({
	open,
	modalHandler,
	locationId,
	data,
	reset,
	filterEmployeeType = "",
}) => {
	const dispatch = useAppDispatch()
	const employeeInformation = useAppSelector(selectEmployeeInformationList())
	const isInformationLoading = useAppSelector(isEmployeeInformationLoading())
	const tickEmoji = <span role="img">{String.fromCodePoint(0x00002714)}</span>
	const crossEmoji = <span role="img">{String.fromCodePoint(0x0000274c)}</span>
	const [selectedRow, setSelectedRow] = useState("")

	const { handleCreateScheduleModalData, createScheduleModalData } = useContext(
		EmployeeRetainContext,
	) as EmployeeRetainContextProps

	const selectHandler = () => {
		reset()
		handleCreateScheduleModalData({
			...createScheduleModalData,
			branch: "",
			supplier: "",
			...(!createScheduleModalData?.checkMultiple ? { employee: selectedRow } : {}),
		})
		modalHandler(false)
	}

	useEffect(() => {
		open && locationId && data && dispatch(getEmployeesInformationByLocation(locationId, data))
	}, [open])

	const filteredEmployeeByPostType = useMemo(() => {
		return employeeInformation.filter(employee => {
			if (!filterEmployeeType) return !employee?.type || employee?.type === EmployeeTypesEnum.SECURITY
			else if (filterEmployeeType === EmployeeTypesEnum.SECURITY)
				return !employee?.type || employee?.type === EmployeeTypesEnum.SECURITY
			else return employee?.type === EmployeeTypesEnum.CLEANING
		})
	}, [filterEmployeeType, employeeInformation])

	return (
		<AntdModal
			open={open}
			title={"Employees Information"}
			onCancel={() => modalHandler(false)}
			okText="Select"
			onOk={selectHandler}
			okButtonProps={{ disabled: !selectedRow }}
			width={600}
		>
			<div className="h-[50vh] w-full flex-col items-center justify-center overflow-y-auto">
				{!isInformationLoading ? (
					<Table2
						headerRowClassName="sticky top-0"
						headerClassName="text-center"
						selectedRows={[selectedRow]}
						rowClassName={"cursor-pointer"}
						onRowClick={setSelectedRow}
						selectRow={true}
						selectedRowColor="#c4e5f8"
						rows={filteredEmployeeByPostType || []}
						columns={[
							{
								label: "Employee",
								key: "firstName",
								render: ({ row, key }: any) => <div> {`${row[key]} ${row["lastName"]}`}</div>,
							},

							{
								label: "Available",
								key: "isAvailable",
								render: ({ row, key }: any) => <div>{row[key] ? tickEmoji : crossEmoji}</div>,
							},
							{
								label: "Worked",
								key: "hasWorked",
								render: ({ row, key }: any) => <div>{row[key] ? tickEmoji : crossEmoji}</div>,
							},
							{
								label: "Not Banned",
								key: "notBanned",
								render: ({ row, key }: any) => <div>{row[key] ? tickEmoji : crossEmoji}</div>,
							},
							{
								label: "Not Resting",
								key: "isNotResting",
								render: ({ row, key }: any) => <div>{row[key] ? tickEmoji : crossEmoji}</div>,
							},
							{
								label: "Trained",
								key: "isTrained",
								render: ({ row, key }: any) => <div>{row[key] ? tickEmoji : crossEmoji}</div>,
							},
							{
								label: "Distance",
								key: "distance",
								render: ({ row, key }: any) => (
									<div>{`${row[key] ? `${meterToMiles(row[key]["value"])} mi` : "N/A"}`}</div>
								),
							},
						]}
					/>
				) : (
					<div className="flex h-full min-w-[550px] items-center justify-center">
						<Icon name="spinner" color="black" size={30} className="animate-spin" />
					</div>
				)}
			</div>
		</AntdModal>
	)
}
export default EmployeeInformationModal
