import React, { useEffect, useState } from "react"
import { TextArea } from "."
import AntdModal from "./AntdModal"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	confirmationText: string
	onConfirmation: (notes: string) => void
	confirmationAction: boolean
	loader?: boolean
}

const ConfirmationModal: React.FC<Props> = ({
	open,
	loader = false,
	modalHandler,
	confirmationText = "yes",
	onConfirmation,
	confirmationAction = false,
}) => {
	const [notes, setNotes] = useState<string>("")

	useEffect(() => {
		setNotes("")
	}, [open])

	const onClick = () => {
		onConfirmation(notes)
	}

	return (
		<AntdModal
			open={open}
			onCancel={() => modalHandler(false)}
			title={"Are you sure?"}
			okText={confirmationText}
			okButtonProps={{ disabled: confirmationAction, loading: loader }}
			onOk={onClick}
		>
			<div className="justify-center sm:flex sm:items-start">
				<div className="w-full">
					<div className="max-w-full pb-4">
						<TextArea
							labelClass="flex flex-col text-sm"
							className="h-20 min-w-full resize-none"
							label="Notes"
							value={notes}
							onChange={({ target: { value } }) => setNotes(value)}
						/>
					</div>
				</div>
			</div>
		</AntdModal>
	)
}

export default ConfirmationModal
