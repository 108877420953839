import { useAppSelector } from "hooks"
import { selectPayrollSaving } from "store/Account/payroll.slice"
import { Input, Table2, TextArea } from "components"
import { useEffect, useMemo, useState } from "react"
import AntdModal from "components/AntdModal"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	parentFormData?: {
		locationRates?: { rate: number; additionalHours: number; location: string }[]
		payslipExpenses?: number
		otherExpenses?: number
	}
	data?: {
		locationHours: { hour: number; location: string; locationName: string }[]
		locationRates?: { rate: number; additionalHours: number; location: string }[]
		payslipExpenses?: number
		otherExpenses?: number
		name: string
		pin: string
		comments?: string
	}
	dataHandler?: (locationRate: any, additionalHours: any, expenses: any, comments: string) => void
}

const LocationHoursModal: React.FC<Props> = ({ open, modalHandler, data, dataHandler, parentFormData }) => {
	const [rateData, setRateData] = useState<{ [key: string]: number }>({})
	const [additionalHoursData, setAdditionalHours] = useState<{ [key: string]: number }>({})
	const [expenses, setExpenses] = useState<{ [key: string]: any }>({})
	const [comments, setComments] = useState<string>("")
	const totalExpenses = (data?.payslipExpenses ?? 0) + (data?.otherExpenses ?? 0)
	const saving = useAppSelector(selectPayrollSaving)

	const filteredTableData = useMemo(() => {
		if (data?.locationRates)
			return data?.locationHours?.map(entity => {
				const existingData = data?.locationRates?.find(entry => entry.location === entity.location)
				if (existingData) {
					setRateData(prev => ({ ...prev, [existingData.location]: existingData?.rate || 0 }))
					setAdditionalHours(prev => ({
						...prev,
						[existingData.location]: existingData?.additionalHours || 0,
					}))
					return {
						...entity,
						rate: existingData.rate,
						additionalHours: existingData.additionalHours,
					}
				} else {
					setRateData(prev => ({ ...prev, [entity.location]: 0 }))
					setAdditionalHours(prev => ({
						...prev,
						[entity.location]: 0,
					}))
					return { ...entity, rate: 0, additionalHours: 0 }
				}
			})
		return [...(data?.locationHours || [])]
	}, [data])

	filteredTableData?.sort((a, b) => a?.locationName?.localeCompare(b?.locationName))

	useEffect(() => {
		setComments(data?.comments || "")
	}, [data])

	return (
		<AntdModal
			width={700}
			open={open}
			title={"Site Hours and Rate"}
			okText="Save"
			okButtonProps={{ loading: saving }}
			onOk={() => {
				dataHandler?.(rateData, additionalHoursData, expenses, comments)
				modalHandler(false)
				setComments("")
				setRateData({})
				setAdditionalHours({})
				setExpenses({})
			}}
			onCancel={() => {
				setAdditionalHours({})
				setRateData({})
				setComments("")
				modalHandler(false)
			}}
		>
			<div>
				<div className="px-4 pt-3 text-left text-sm font-bold uppercase tracking-wider text-dark">
					Employee Name: {data?.name}
				</div>
				<div className="px-4 pb-1 text-left text-sm font-bold uppercase tracking-wider text-dark">
					PIN: {data?.pin}
				</div>
				<>
					{!!filteredTableData?.length && (
						<div className="max-h-[60vh] overflow-y-auto">
							<Table2
								headerRowClassName="sticky top-0"
								rows={filteredTableData || []}
								columns={[
									{
										label: "Site Name",
										key: "locationName",
										className: "text-left pl-5",
									},
									{
										label: "Hours",
										key: "hour",
										className: "text-left pl-5",
										render: ({ row, key }) => <div>{row[key]}</div>,
									},
									{
										label: "Pay Rate",
										key: "location",
										className: "text-left",
										render: ({ row, key }) => (
											<div className="flex items-center space-x-1">
												<div className="text-center text-md">£</div>
												<Input
													className={`h-0.5 w-16  text-sm`}
													value={
														rateData?.[row[key]] ??
														parentFormData?.locationRates?.find(
															ent => ent.location === row[key],
														)?.rate ??
														(row["rate"] || "")
													}
													onChange={e => {
														setRateData(prev => ({
															...prev,
															[row[key]]: e.target.value,
														}))
													}}
													inputMode="numeric"
												/>
											</div>
										),
									},
									{
										label: "Additional Hours",
										key: "location",
										className: "text-left",
										render: ({ row, key }) => (
											<div className="flex items-center space-x-1">
												<Input
													className={`h-0.5 w-16  text-sm`}
													value={
														additionalHoursData?.[row[key]] ??
														parentFormData?.locationRates?.find(
															ent => ent.location === row[key],
														)?.additionalHours ??
														(row["additionalHours"] || "")
													}
													onChange={e => {
														setAdditionalHours(prev => ({
															...prev,
															[row[key]]: e.target.value,
														}))
													}}
													inputMode="numeric"
												/>
											</div>
										),
									},
								]}
							/>
						</div>
					)}
					<div className="mt-10 ml-5 flex justify-start space-x-8">
						<div className="flex justify-start space-x-2">
							<Input
								className={`h-0.5 w-16  text-sm`}
								label="Payslip Expenses £"
								placeholder="0"
								value={expenses?.payslipExpenses ?? (data?.payslipExpenses || "")}
								onChange={e => {
									setExpenses(prev => ({
										...prev,
										payslipExpenses: e.target.value,
									}))
								}}
								inputMode="numeric"
							/>
						</div>
						<div className="flex justify-start space-x-2">
							<Input
								className={`h-0.5 w-16  text-sm`}
								label="Other Expenses £"
								placeholder="0"
								value={expenses?.otherExpenses ?? (data?.otherExpenses || "")}
								onChange={e => {
									setExpenses(prev => ({
										...prev,
										otherExpenses: e.target.value,
									}))
								}}
								inputMode="numeric"
							/>
						</div>
						<div className="flex justify-start space-x-2">
							<span>
								Total Expenses:
								<span className="ml-1 font-semibold">£{totalExpenses}</span>
							</span>
						</div>
					</div>
					<div className="mt-4 ml-5 flex flex-col">
						<div className="font-semibold">Notes</div>
						<TextArea
							className="h-20 w-2/3"
							value={comments}
							onChange={({ target: { value } }) => setComments(value)}
						/>
					</div>
				</>
			</div>
		</AntdModal>
	)
}
export default LocationHoursModal
