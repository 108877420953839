import React, { useEffect, useMemo, useState } from "react"
import { AntdSelect, Icon } from "components"
import { useAppDispatch, useAppSelector, useFeatureFlags } from "hooks"
import { isLoadingAllowedResources, selectUserAccessRoles } from "store/Security/roles.slice"
import { Switch } from "antd"
import { PermitterForEnum, USER_ACCESS_MAPPING } from "config/constants"
import { selectCurrentUser } from "store/auth.slice"
import { GetRoleInterface, GetUserInterface } from "@type/security.types"
import { saveUser } from "store/Security/users.slice"
import { isArray } from "lodash"
import { FeatureFlagEnums } from "types/featureFlags.type"
import AntdModal from "./AntdModal"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	user: GetUserInterface | null
	callbackfn: (data: GetRoleInterface[]) => void
	setSelectedUser: (user: string) => void
	userName?: string
}
interface PayloadInterface {
	[key: string]: {
		hasCompanyWideAccess?: boolean
		name?: string
		organization?: string
		isOrganizationalAdmin?: boolean
		permittedFor?: string
		functionsPermitted?: []

		employees?: { allAllowed: boolean; list: [] }
		branches?: { allAllowed: boolean; list: [] }
		locations?: { allAllowed: boolean; list: [] }
		customers?: { allAllowed: boolean; list: [] }
		suppliers?: { allAllowed: boolean; list: [] }
	}
}
const superAdminAllowedFunctions = {
	PayRoll: {
		VIEW_PAYROLL: "View",
		FEATURE_NAME: FeatureFlagEnums.ACCOUNTS,
	},
	"SIA Validation": {
		BYPASS_SIA_VALIDATION: "Allow Bypass",
		FEATURE_NAME: FeatureFlagEnums.ADMIN,
	},
	"NIN Validation": {
		BYPASS_NIN_VALIDATION: "Allow Bypass",
		FEATURE_NAME: FeatureFlagEnums.ADMIN,
	},
	"Max Consecutive Workdays Validation": {
		BYPASS_MAX_CONSECUTIVE_WORKDAYS_VALIDATION: "Allow Bypass",
		FEATURE_NAME: FeatureFlagEnums.ADMIN,
	},
}

const allowedFunctionsEnum = {
	Employee: {
		CREATE_AND_UPDATE_EMPLOYEE: "Create/Update",
		VIEW_EMPLOYEE: "View",
		FEATURE_NAME: FeatureFlagEnums.EMPLOYEES,
	},
	Location: {
		CREATE_AND_UPDATE_LOCATION: "Create/Update",
		VIEW_LOCATION: "View",
		FEATURE_NAME: FeatureFlagEnums.LOCATIONS,
	},
	Schedules: {
		CREATE_AND_UPDATE_SCHEDULES: "Create/Update",
		VIEW_SCHEDULES: "View",
		FEATURE_NAME: FeatureFlagEnums.SCHEDULES,
	},
	"Work Monitor": {
		CREATE_AND_UPDATE_WORK_MONITOR: "Create/Update",
		VIEW_WORK_MONITOR: "View",
		FEATURE_NAME: FeatureFlagEnums.COMMAND_CENTER,
	},
	Admin: {
		VIEW_ADMIN: "View",
		FEATURE_NAME: FeatureFlagEnums.ADMIN,
	},
	Reports: {
		VIEW_REPORTS: "Allowed",
		DOWNLOAD_REPORTS: "Print/Download",
		FEATURE_NAME: FeatureFlagEnums.REPORTS,
	},
}

type listNames =
	| "CREATE_AND_UPDATE_EMPLOYEE"
	| "VIEW_EMPLOYEE"
	| "CREATE_AND_UPDATE_LOCATION"
	| "VIEW_LOCATION"
	| "CREATE_AND_UPDATE_SCHEDULES"
	| "VIEW_SCHEDULES"
	| "CREATE_AND_UPDATE_WORK_MONITOR"
	| "VIEW_WORK_MONITOR"
	| "VIEW_ADMIN"
	| "VIEW_REPORTS"
	| "DOWNLOAD_REPORTS"
	| "VIEW_PAYROLL"

const UserAccessModal: React.FC<Props> = ({ open, modalHandler, user, callbackfn, userName = "" }) => {
	const dispatch = useAppDispatch()
	const list: {
		_id: string
		name: string
		employees: string
		locations: string
		branches: string
		customers: string
		suppliers: string
	}[] = useAppSelector(selectUserAccessRoles())
	const { featureFlagEnabled } = useFeatureFlags()
	const [formData, setFormData] = useState<PayloadInterface>({})
	const [allowedFunctions, setAllowedFunctions] = useState<string[]>([])
	const currentUser = useAppSelector(selectCurrentUser())
	const loadingAllowedResources = useAppSelector(isLoadingAllowedResources())
	const [showDropDown, setShowDropDown] = useState<boolean>(false)

	const handleDropDown = () => {
		setShowDropDown(prev => !prev)
	}

	const existingAccessMemo = useMemo(() => {
		const data: { [key: string]: any } = {}
		if (user && user.roles) {
			user?.roles?.forEach(role => {
				const { allowedResources, ...rest } = role
				const functionsPermitted = Object.entries(USER_ACCESS_MAPPING).filter(entry => {
					return rest?.functionsPermitted?.some(func => {
						return entry[1]?.includes(func)
					})
				})
				setAllowedFunctions(Object.values(functionsPermitted).map(val => val[0]))

				data[role.organization ?? 0] = {
					...rest,
					locations: allowedResources?.locations,
					branches: allowedResources?.branches,
					customers: allowedResources?.customers,
					suppliers: allowedResources?.suppliers,
					employees: allowedResources?.employees,
					isNew: false,
				}
			})
		}
		return data
	}, [user, user?.roles])

	const submitHandler = () => {
		if (currentUser) {
			let payload: GetRoleInterface[] = []

			const permittedFunctions: any[] = []
			allowedFunctions.forEach(func => {
				permittedFunctions.push(...USER_ACCESS_MAPPING[func as listNames])
			})

			if (formData[0]?.hasCompanyWideAccess) {
				payload = [
					{
						name: `${user?.userName ?? userName}`,
						hasCompanyWideAccess: true,
						permittedFor: PermitterForEnum.COMPANY,
						functionsPermitted: permittedFunctions,
					},
				]
			} else {
				Object.entries(formData).forEach(([key, value]) => {
					if (key !== "0") {
						const { locations, branches, customers, suppliers, employees, ...rest } = value
						if (
							locations?.allAllowed ||
							!!locations?.list?.length ||
							branches?.allAllowed ||
							!!branches?.list?.length ||
							customers?.allAllowed ||
							!!customers?.list?.length ||
							suppliers?.allAllowed ||
							!!suppliers?.list?.length ||
							employees?.allAllowed ||
							!!employees?.list?.length ||
							rest?.isOrganizationalAdmin
						) {
							// if(locations?.list.map(loc=>loc))
							payload.push({
								name: `${user?.userName ?? userName}-${
									list?.find((entity: any) => entity?._id === key)?.name
								}`,
								...rest,
								organization: key,
								functionsPermitted: permittedFunctions,
								permittedFor: PermitterForEnum.COMPANY,
								allowedResources: {
									locations: {
										allAllowed: locations?.allAllowed,
										list: locations?.allAllowed ? [] : locations?.list,
									},
									branches: {
										allAllowed: branches?.allAllowed,
										list: branches?.allAllowed ? [] : branches?.list,
									},
									customers: {
										allAllowed: customers?.allAllowed,
										list: customers?.allAllowed ? [] : customers?.list,
									},
									suppliers: {
										allAllowed: suppliers?.allAllowed,
										list: suppliers?.allAllowed ? [] : suppliers?.list,
									},
									employees: {
										allAllowed: employees?.allAllowed,
										list: employees?.allAllowed ? [] : employees?.list,
									},
								},
							})
						}
					}
				})
			}
			callbackfn(payload)
			if ((user?.roles && user?.roles.length > 0) || user?._id) {
				dispatch(saveUser({ ...user, roles: payload }))
			} else {
				modalHandler(false)
			}
		}
	}

	useEffect(() => {
		if (!!user?.roles.length && Object.keys(existingAccessMemo).length) {
			setFormData(existingAccessMemo as PayloadInterface)
		} else {
			setAllowedFunctions([])
			setFormData({})
		}
	}, [existingAccessMemo, user])

	return (
		<AntdModal
			open={open}
			width={1500}
			onCancel={() => modalHandler(false)}
			onOk={() => submitHandler()}
			okText="Save"
			title="User Access"
		>
			{loadingAllowedResources ? (
				<div className="flex h-full w-[1100px] items-center justify-center py-10">
					<Icon name="spinner" color="white" size={80} className="mb-16 animate-spin !text-purple" />
				</div>
			) : (
				<>
					<div className="space-y-15 mb-4 min-w-[1045px] flex-col items-center justify-center">
						<div className="flex w-full space-x-6">
							<div className="w-2/3">
								<form>
									{currentUser?.roles?.some(role => role?.hasCompanyWideAccess) && (
										<>
											<label className="ml-4 mr-2 text-md font-normal">Full Access</label>
											<Switch
												className="mb-12"
												checked={formData[0]?.hasCompanyWideAccess ?? false}
												onChange={e => {
													setFormData({
														...formData,
														"0": { ...formData[0], hasCompanyWideAccess: e },
													})
												}}
											></Switch>
										</>
									)}

									<div
										className={`${
											formData["0"]?.hasCompanyWideAccess ? "pointer-events-none opacity-50" : ""
										}`}
									>
										{list &&
											list?.map((entity: any, index: number) => (
												<div key={index}>
													<EntityView
														row={entity}
														setFormData={setFormData}
														formData={formData}
													/>
												</div>
											))}
										<span>
											<></>
										</span>
									</div>
								</form>
							</div>
							<div className="mb-4 w-1/3">
								<div className="m-2 my-4 mt-9 rounded-lg border-[0.75px] bg-slate-50 p-2 font-semibold">
									<div className="mb-4  flex justify-between">
										<div
											className="flex h-12 w-full cursor-pointer items-center"
											onClick={handleDropDown}
										>
											<Icon
												name={showDropDown ? "dropdownArrow" : "dropRightArrow"}
												size={20}
												color="black"
											/>
											<span>Allowed Functions</span>
										</div>
									</div>

									<div
										className={`flex flex-col space-y-2 px-2 py-2 ${
											showDropDown ? "visible" : " hidden"
										} `}
									>
										<div className="mb-4 flex-col space-y-8">
											{Object.entries({
												...allowedFunctionsEnum,
												...(currentUser?.isSuperAdmin && superAdminAllowedFunctions),
											})
												.filter(([_, value]) => featureFlagEnabled(value?.FEATURE_NAME))
												.map(([key, { ...value }], index) => {
													delete (value as any).FEATURE_NAME
													return (
														<div className="flex-col space-y-2" key={index}>
															<span>{key}</span>
															<div className="flex justify-between ">
																{Object.entries(value).map(([cKey, cValue], cIndex) => {
																	return (
																		<div
																			className="flex space-x-2 text-md font-normal"
																			key={cIndex}
																		>
																			<span className="">{cValue}</span>
																			<Switch
																				checked={allowedFunctions.includes(
																					cKey,
																				)}
																				onChange={() => {
																					setAllowedFunctions([
																						...(allowedFunctions.includes(
																							cKey,
																						)
																							? allowedFunctions.filter(
																									func =>
																										func !== cKey,
																							  )
																							: [
																									...allowedFunctions,
																									cKey,
																							  ]),
																					])
																				}}
																			></Switch>
																		</div>
																	)
																})}
															</div>
														</div>
													)
												})}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</AntdModal>
	)
}
export default UserAccessModal

interface RowInterface {
	_id: string
	name: string
	isOrganizationalAdmin: boolean
	branches: { name: string; _id: string }[]
	customers: { name: string; _id: string }[]
	locations: { name: string; _id: string; branch: string; customer: string }[]
	suppliers: { name: string; _id: string }[]
	employees: { name: string; _id: string }[]
}
interface ViewPropsInterface {
	row: RowInterface
	setFormData: React.Dispatch<any>
	formData: any
}

enum formFields {
	"Branches" = "branches",
	"Customers" = "customers",
	"Locations" = "locations",
	"Suppliers" = "suppliers",
	"Employees" = "employees",
}

type subFieldNames = "branch" | "customer" | "supplier"
type fieldNames = "branches" | "customers" | "locations" | "suppliers" | "employees"

const fieldsToPopulate: { name: fieldNames; fields: { [key: string]: subFieldNames } }[] = [
	{
		name: "locations",
		fields: {
			branches: "branch",
			customers: "customer",
		},
	},
	{
		name: "employees",
		fields: {
			branches: "branch",
			suppliers: "supplier",
		},
	},
]

const EntityView: React.FC<ViewPropsInterface> = ({ row, formData, setFormData }) => {
	const [showDropDown, setShowDropDown] = useState<boolean>(false)

	const handleDropDown = () => {
		setShowDropDown(prev => !prev)
	}

	useEffect(() => {
		if (formData["0"]?.hasCompanyWideAccess) {
			setShowDropDown(false)
		}
	}, [formData["0"]?.hasCompanyWideAccess])

	return (
		<>
			<div className="m-2 my-4 rounded-lg border-[0.75px] bg-slate-100 p-2 font-semibold">
				<div className="flex justify-between">
					<div className="flex h-12 w-full cursor-pointer items-center" onClick={handleDropDown}>
						<Icon name={showDropDown ? "dropdownArrow" : "dropRightArrow"} size={20} color="black" />
						<div className="flex w-full justify-between">
							<span>{row?.name}</span>
							<span
								onClick={e => {
									e.stopPropagation()
									e.preventDefault()
								}}
							>
								<label className="mr-2 text-md font-normal">Full Admin Access</label>
								<Switch
									onClick={e => {
										setFormData({
											...formData,
											[row._id]: { ...formData[row?._id], isOrganizationalAdmin: e },
										})
									}}
									checked={formData?.[row?._id]?.isOrganizationalAdmin}
								></Switch>
							</span>
						</div>
					</div>
				</div>
				<div className={`flex flex-col space-y-2 px-2 py-2 ${showDropDown ? "visible" : " hidden"} `}>
					<div className="flex-col space-y-4">
						{Object.entries(formFields).map(([label, value], index) => (
							<div key={index}>
								{label}
								<span className="flex items-center justify-between">
									<span className="flex w-1/2 text-sm font-normal">
										<span className="w-4/6">Give Access to All {label}</span>
										<Switch
											onChange={e => {
												setFormData({
													...formData,
													[row._id]: {
														...formData[row._id],
														[value]: {
															...formData?.[row?._id]?.[value],
															allAllowed: e,
														},
													},
												})
											}}
											checked={formData?.[row?._id]?.[value]?.allAllowed}
										></Switch>
									</span>
									<span className="w-1/2">
										<AntdSelect
											disabled={formData?.[row?._id]?.[value]?.allAllowed}
											onChange={e => {
												fieldsToPopulate.forEach(fieldsObj => {
													if (value === fieldsObj.name) {
														Object.entries(fieldsObj.fields).forEach(([key, val]) => {
															if (
																isArray(e) &&
																!formData?.[row?._id]?.[key]?.allAllowed
															) {
																const toAddEntity =
																	(row[fieldsObj.name] as any).find(
																		(loc: { _id: string }) =>
																			loc._id ===
																			e.filter(
																				x =>
																					!formData?.[row?._id]?.[
																						value
																					]?.list?.includes(x),
																			)[0],
																	)?.[val] ?? undefined

																if (toAddEntity) {
																	if (
																		!formData?.[row._id]?.[key]?.list.includes(
																			toAddEntity,
																		) ||
																		!formData?.[row._id]?.[key]?.list
																	)
																		formData[row._id] = {
																			...formData[row._id],
																			[key]: {
																				...formData?.[row._id]?.[key],
																				list: [
																					...(formData?.[row._id]?.[key]
																						?.list ?? []),
																					toAddEntity,
																				],
																			},
																		}
																}
															}
														})
													}
												})

												setFormData({
													...formData,
													[row._id]: {
														...formData[row._id],
														[value]: { ...formData?.[row._id]?.[value], list: e },
													},
												})
											}}
											mode="multiple"
											selected={formData?.[row?._id]?.[value]?.list ?? []}
											label={label}
											name={value}
											containerClass="w-auto h-1! space-y-0"
											className=" h-12! w-full flex-nowrap overflow-x-clip"
											options={row[value]
												?.map(loc => ({
													label: loc?.name,
													value: loc?._id,
												}))
												.sort((a, b) =>
													a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
												)}
											maxTagLength={22}
											size="small"
											allowClear={true}
											isControlled={true}
										/>
									</span>
								</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	)
}
