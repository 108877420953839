import * as Yup from "yup"

export const SDIncidentInitialValues = {
	name: "",
	description: "",
	defaultEscalationType: "Select Escalation Type",
	priority: "",
	shownInWorkMonitor: false,
	isCompanyWide: false,
	isDescriptionRequired: false,
	isEnabled: false,
}

export const SDIncidentSchema = Yup.object().shape({
	name: Yup.string().required().label("Short").trim(),
	description: Yup.string().optional().label("Description").trim(),
	defaultEscalationType: Yup.string().optional().label("Default Escalation Type"),
	priority: Yup.number().optional().nullable().label("Priority"),
	shownInWorkMonitor: Yup.boolean().optional().label("ShownInWorkMonitor"),
	isCompanyWide: Yup.boolean().optional().nullable().label("Is Company Wide"),
	isDescriptionRequired: Yup.boolean().optional().label("Incident Description Required"),
	isEnabled: Yup.boolean().optional().label("Enabled"),
})
