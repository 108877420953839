import * as Yup from "yup"

export const SDQualificationInitialValues = {
	name: "",
	PBCode: "",
	matchedPBCode: "",
	canBeOverridden: false,
}

export const SDQualificationSchema = Yup.object().shape({
	name: Yup.string().required().label("Name").trim(),
	PBCode: Yup.string().required().label("PB Code"),
	matchedPBCode: Yup.string().required().label("Matched PB Code"),
	canBeOverridden: Yup.boolean().required().label("Can be Overridden"),
})
