import { Modal, ModalProps } from "antd-v5"
import React from "react"

interface Props extends ModalProps {}

const AntdModal: React.FC<Props> = ({ open, onCancel, onOk, title, children, ...rest }) => {
	return (
		<div>
			<Modal
				open={open}
				onOk={onOk}
				onCancel={onCancel}
				title={<div className="text-center">{title}</div>}
				{...rest}
				okButtonProps={{
					...rest.okButtonProps,
					style: !rest.okButtonProps?.disabled ? { backgroundColor: "#6366f1" } : {},
				}}
			>
				<div className="max-h-[67vh] overflow-y-auto">{children}</div>
			</Modal>
		</div>
	)
}

export default AntdModal
