import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form"
import {
	TimeField,
	NearbyEmployeesModal,
	FormSelectAntd,
	AntdSelect1,
	FormInputAntd,
	AntdButton,
	FormCheckbox,
} from "components"
import {
	CreateSchedulesInterface,
	FilterEmployeeFromSupplierOrBranchInterface,
	SelectedSchedules,
} from "@type/schedules.types"
import { SchedulesByLocationValidationSchema } from "Schema"
import { useContext, useEffect, useMemo, useState } from "react"
import { AddDateIfFinishTimeIsGreater, convertDateTimeStringToIsoUtc, convertDateTimeToTime } from "config/utils"
import { filterEmployeeByBranchOrSupplierName } from "store/Employee/detail.slice"
import useAppSelector from "hooks/useAppSelector"
import { selectBranchesMappedAsOptions } from "store/Branches/branch.slice"
import { selectSDSuppliersDropdownList } from "store/StaticData/SDSuppliers.slice"
import { EmployeeTypesEnum, Permissions, ScheduleTypesEnum, byLocationScheduleType } from "config/constants"
import EmployeeRetainContext, {
	EmployeeRetainContextProps,
	CreateScheduleRetainSource,
} from "./CreateScheduleRetainContext"
import { selectCurrentUser } from "store/auth.slice"
import { saveSchedule, selectScheduleSaving } from "store/Schedules/schedules.slice"
import { GetPostsInterface } from "@type/locations.types"
import useAppDispatch from "hooks/useAppDispatch"
import { selectSelectedLocation } from "store/globalState.slice"
import { getEmployeesNearbyLocation } from "store/Geolocation/geolocation.slice"
import { selectLocationById } from "store/Location/locationDetails.slice"
import { Switch } from "antd"
import { AllowedTo } from "react-abac"
import AntdModal from "components/AntdModal"
import { useAbac } from "react-abac"

export interface Payload {
	postId: string
	dates: { startTime: string; finishTime: string }[]
}

export const sortSchedulesByRowColumn = (data: SelectedSchedules) => {
	return Object.keys(data).sort((a, b) => {
		const first = +a.split("_").slice(2).join("")
		const second = +b.split("_").slice(2).join("")

		return first - second
	})
}

export const extractPayloadForScheduleCreationFromTable = (
	selectedCells: SelectedSchedules,
	startTime: string,
	finishTime: string,
) => {
	const sortedSchedules = sortSchedulesByRowColumn(selectedCells)

	const payload: Payload[] = []
	sortedSchedules.forEach(scheduleCellData => {
		const [postId, scheduleDate] = scheduleCellData.split("_")

		const starTimeMoment = convertDateTimeStringToIsoUtc(`${scheduleDate} ${startTime}`)
		let finishTimeMoment = convertDateTimeStringToIsoUtc(`${scheduleDate} ${finishTime}`)

		finishTimeMoment = AddDateIfFinishTimeIsGreater(starTimeMoment, finishTimeMoment)

		// If postAlreadyExists
		const samePostIndex = payload.findIndex(scheduleData => scheduleData.postId === postId)

		// Append the same post
		if (samePostIndex >= 0) {
			payload[samePostIndex].dates.push({ startTime: starTimeMoment, finishTime: finishTimeMoment })
		}
		// else create new post
		else {
			payload.push({ postId, dates: [{ startTime: starTimeMoment, finishTime: finishTimeMoment }] })
		}
	})

	return payload
}

const extractPayloadForScheduleUpdationFromTable = (
	selectedCells: SelectedSchedules,
	startTime: string,
	finishTime: string,
) => {
	const payload = Object.values(selectedCells)
		.map(scheduleData => {
			if (scheduleData !== null) {
				const starTimeMoment = convertDateTimeStringToIsoUtc(`${scheduleData.date} ${startTime}`)
				let finishTimeMoment = convertDateTimeStringToIsoUtc(`${scheduleData.date} ${finishTime}`)

				finishTimeMoment = AddDateIfFinishTimeIsGreater(starTimeMoment, finishTimeMoment)

				return {
					_id: scheduleData._id,
					startTime: starTimeMoment,
					finishTime: finishTimeMoment,
				}
			} else return null
		})
		.filter(payload => payload !== null)
	// Filtering undefined values only if someone manges to enable update button and sending empty schedules

	return payload
}

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	postData: GetPostsInterface | null
	showInfo: { [key: string]: string }
	selectedSchedule: SelectedSchedules
	isUpdating: boolean
	employeeList: { _id: string; name: string }[]
	employeeRowId: string
	infoModalHandler: (open: boolean) => void
	infoModalData: (startTime: string, finishTime: string, reset: () => void) => void
	recurringModalData?: (data: CreateSchedulesInterface) => void
	setRecurringModalOpen: (open: boolean) => void
	setShowEmployeeModal: (open: boolean) => void
	isRecurring?: boolean
	resetTable: () => void
}

export interface CreateScheduleFormInterface {
	startTime: string
	finishTime: string
	employee?: string
	createMultiple?: string
	type: ScheduleTypesEnum
	notes: string
}

const CreateScheduleModal: React.FC<Props> = ({
	open,
	postData,
	modalHandler,
	showInfo,
	selectedSchedule = {},
	isUpdating = true,
	isRecurring = false,
	infoModalHandler,
	setRecurringModalOpen,
	setShowEmployeeModal,
	infoModalData,
	resetTable = () => undefined,
}) => {
	const methods = useForm<CreateSchedulesInterface>({
		resolver: yupResolver(SchedulesByLocationValidationSchema),
		shouldUnregister: true,
	})

	const {
		handleSubmit,
		setValue,
		getValues,
		watch,
		reset,
		formState: { isDirty },
	} = methods

	const dispatch = useAppDispatch()
	//Selectors
	const branchList = useAppSelector(selectBranchesMappedAsOptions())
	const supplierList = useAppSelector(selectSDSuppliersDropdownList)
	const user = useAppSelector(selectCurrentUser())
	const isScheduleSaving = useAppSelector(selectScheduleSaving())

	const [isModalOpen, setIsModalOpen] = useState(false)

	// Contexts
	const { createScheduleModalData, handleCreateScheduleModalData } = useContext(
		EmployeeRetainContext,
	) as EmployeeRetainContextProps
	const selected = useAppSelector(selectSelectedLocation)
	const location = useAppSelector(selectLocationById(selected))

	//States
	const [filterOptions, setFilterOptions] = useState<FilterEmployeeFromSupplierOrBranchInterface>({
		filterFrom: postData?.type ? "type" : "",
		filterId: postData?.type ? postData?.type : "",
	})

	const filterEmployeeListByBranchOrSupplierName = useAppSelector(
		filterEmployeeByBranchOrSupplierName(filterOptions.filterId, filterOptions.filterFrom),
	)

	const { userHasPermissions } = useAbac()

	useEffect(() => {
		const firstSelectedCellData = Object.values(selectedSchedule)[0]
		if (postData) {
			if (isUpdating && firstSelectedCellData !== null) {
				if ("startTime" in firstSelectedCellData) {
					const { startTime = "", finishTime = "" } = firstSelectedCellData
					setValue("startTime", convertDateTimeToTime(startTime), { shouldDirty: false })
					setValue("finishTime", convertDateTimeToTime(finishTime), { shouldDirty: false })
				}
			} else {
				const { startTime = "", finishTime = "" } = postData
				if (createScheduleModalData !== null) {
					reset(createScheduleModalData)
					setValue("employee", createScheduleModalData.employee, { shouldDirty: true })
					setValue(
						"bypassSiaVerification",
						createScheduleModalData?.bypassSiaVerification || watch("bypassSiaVerification"),
					)
					setValue(
						"bypassMaxConsecutiveWorkdaysVerification",
						createScheduleModalData?.bypassMaxConsecutiveWorkdaysVerification ||
							watch("bypassMaxConsecutiveWorkdaysVerification"),
					)
				} else {
					reset({ type: byLocationScheduleType[0].value })
					setValue("bypassSiaVerification", false)
					setValue("bypassMaxConsecutiveWorkdaysVerification", false)
				}
				setValue("startTime", createScheduleModalData?.startTime ?? startTime, { shouldDirty: true })
				setValue("finishTime", createScheduleModalData?.finishTime ?? finishTime, { shouldDirty: true })
			}
		}
	}, [postData, isUpdating, selectedSchedule, createScheduleModalData])

	useEffect(() => {
		setFilterOptions({ filterFrom: postData?.type ? "type" : "", filterId: postData?.type ? postData?.type : "" })
	}, [postData])

	// const handleCheck = (event: any) => {
	// 	const checked = event?.target.checked
	// 	if (!checked) {
	// 		setValue("createMultiple", "")
	// 	} else setValue("employee", "")
	// }

	const handleResetFilterOption = () => {
		setValue("branch", "")
		setValue("supplier", "")
		setFilterOptions({ filterFrom: postData?.type ? "type" : "", filterId: postData?.type ? postData?.type : "" })
	}

	const handleButton = () => {
		infoModalHandler(true)
		infoModalData(getValues("startTime"), getValues("finishTime"), handleResetFilterOption)
		handleCreateScheduleModalData(getValues())
	}
	const handleRecurringButton = () => {
		handleCreateScheduleModalData({
			source: CreateScheduleRetainSource.RECURRING,
			payload: sortSchedulesByRowColumn(selectedSchedule),
			...getValues(),
		})
		setRecurringModalOpen(true)
		modalHandler(false)
	}

	const handleViewEmployeeDetails = () => {
		handleCreateScheduleModalData({ source: CreateScheduleRetainSource.VIEW_EMPLOYEE_DETAILS, ...getValues() })
		setShowEmployeeModal(true)
		modalHandler(false)
	}

	const onSubmit = (data: CreateSchedulesInterface) => {
		if (isUpdating) {
			const { notes, startTime, finishTime } = data
			const payload = {
				notes,
				ids: extractPayloadForScheduleUpdationFromTable(selectedSchedule, startTime, finishTime),
			}

			dispatch(saveSchedule(payload, () => resetTable()))
		} else {
			const {
				startTime,
				finishTime,
				employee,
				type,
				createMultiple,
				notes,
				bypassSiaVerification,
				bypassMaxConsecutiveWorkdaysVerification,
			} = data

			const payload = {
				...(employee && { employee }), // If employee exists it will be added in payload
				...(createMultiple && { createMultiple }),
				location: selected,
				posts: extractPayloadForScheduleCreationFromTable(selectedSchedule, startTime, finishTime),
				type,
				user: user?._id,
				notes: notes?.trim(),
				bypassSiaVerification,
				bypassMaxConsecutiveWorkdaysVerification,
			}

			dispatch(saveSchedule(payload, () => resetTable()))
		}
	}

	useEffect(() => {
		if (
			createScheduleModalData?.employee &&
			filterEmployeeListByBranchOrSupplierName.some(
				employee => employee.value !== createScheduleModalData?.employee,
			)
		)
			setFilterOptions({
				filterFrom: postData?.type ? "type" : "",
				filterId: postData?.type ? postData?.type : "",
			})
	}, [createScheduleModalData?.employee])

	const searchNearbyPostTime = useMemo(() => {
		if (!postData || !postData?.type) return EmployeeTypesEnum.SECURITY
		else if (postData?.type === EmployeeTypesEnum.SECURITY) return EmployeeTypesEnum.SECURITY
		else return EmployeeTypesEnum.CLEANING
	}, [postData])

	return (
		<>
			<AntdModal
				open={open}
				title={(isRecurring ? "Recurring" : isUpdating ? "Update" : "Create") + " " + "Schedules"}
				onCancel={() => {
					modalHandler(false)
					setValue("employee", "")
				}}
				onOk={isRecurring ? handleRecurringButton : handleSubmit(onSubmit)}
				okText={isRecurring ? "Next" : "Save"}
				okButtonProps={{
					loading: isScheduleSaving,
					disabled: isRecurring
						? watch("startTime")?.includes("Invalid") || watch("finishTime")?.includes("Invalid")
						: !isDirty && !createScheduleModalData,
				}}
			>
				<FormProvider {...methods}>
					<form className="w-full">
						<div className="flex items-center justify-between py-2">
							<div className="flex flex-col text-sm font-semibold text-dark">
								<span>Site: {showInfo.location}</span>
								<span> Posts: {postData?.name}</span>
							</div>
							<div className={`flex items-center justify-end space-x-2`}>
								{!isUpdating && (
									<AntdButton htmlType="button" onClick={handleButton}>
										Best Fit
									</AntdButton>
								)}
								{location && (
									<AntdButton
										onClick={e => {
											e.preventDefault()
											setIsModalOpen(true)
											dispatch(getEmployeesNearbyLocation(location?._id, searchNearbyPostTime))
										}}
										htmlType="button"
									>
										Nearby
									</AntdButton>
								)}
							</div>
						</div>

						<div className="grid grid-cols-2 gap-x-3 gap-y-2">
							<div className="col-span-2">
								<FormSelectAntd
									label="Schedule Type"
									name="type"
									options={byLocationScheduleType?.map(({ label, value }) => ({ label, value }))}
								/>
							</div>
							<TimeField
								label="Start Time"
								watch={watch}
								formValueSetter={setValue}
								name="startTime"
								defaultFocusOn={true}
							/>
							<TimeField label="Finish Time" watch={watch} formValueSetter={setValue} name="finishTime" />
							{!isUpdating && (
								<>
									<AntdSelect1
										label="Branch"
										placeholder="Select Branch"
										value={watch("branch")}
										onChange={value => {
											if (value) {
												setValue("branch", value)
												setValue("supplier", "Select Supplier")
												setFilterOptions({ filterId: value, filterFrom: "branch" })
											}
										}}
										options={[
											{ label: "Select Branch", value: "Select Branch" },
											...branchList,
										]?.map(({ label, value }) => ({ label, value }))}
									/>
									<AntdSelect1
										label="Supplier"
										value={watch("supplier")}
										placeholder="Select Supplier"
										onChange={value => {
											if (value) {
												setValue("supplier", value)
												setValue("branch", "Select Branch")
												setFilterOptions({ filterId: value, filterFrom: "supplier" })
											}
										}}
										options={[...supplierList]?.map(({ label, value }) => ({ label, value }))}
									/>
									<AntdSelect1
										disabled={watch("checkMultiple")}
										label="Employee"
										value={watch("employee") || createScheduleModalData?.employee}
										onChange={value => {
											if (value) {
												setValue("employee", value)
												handleCreateScheduleModalData({
													...getValues(),
													employee: value,
												})
											}
										}}
										options={[
											{ label: "Select Employee", value: "" },
											...filterEmployeeListByBranchOrSupplierName,
										]?.map(({ label, value }) => ({ label, value }))}
									/>
									{!isRecurring && (
										<div>
											<FormCheckbox name="checkMultiple" label="Create Multiple" />
											<FormInputAntd
												type="text"
												name="createMultiple"
												disabled={!watch("checkMultiple")}
											/>
										</div>
									)}
									<div className="col-span-2">
										{userHasPermissions(Permissions.VIEW_EMPLOYEE_DETAILS) && (
											<div
												className={`cursor-pointer p-1 text-sm text-dark hover:underline ${
													watch("employee") || createScheduleModalData ? "visible" : "hidden"
												}`}
												onClick={handleViewEmployeeDetails}
											>
												View Details
											</div>
										)}
									</div>
								</>
							)}
						</div>

						<AllowedTo perform={Permissions.BYPASS_SIA_VALIDATION}>
							<div
								className="mt-4 mb-2 w-fit cursor-pointer"
								onClick={() => setValue("bypassSiaVerification", !watch("bypassSiaVerification"))}
							>
								<label className="mr-2 cursor-pointer text-md font-normal">
									Bypass SIA verification
								</label>
								<Switch checked={watch("bypassSiaVerification")} />
							</div>
						</AllowedTo>

						<AllowedTo perform={Permissions.BYPASS_MAX_CONSECUTIVE_WORKDAYS_VALIDATION}>
							<div
								className="mt-4 mb-2 w-fit cursor-pointer"
								onClick={() =>
									setValue(
										"bypassMaxConsecutiveWorkdaysVerification",
										!watch("bypassMaxConsecutiveWorkdaysVerification"),
									)
								}
							>
								<label className="mr-2 cursor-pointer text-md font-normal">
									Bypass Max Consecutive Workdays verification
								</label>
								<Switch checked={watch("bypassMaxConsecutiveWorkdaysVerification")} />
							</div>
						</AllowedTo>

						<FormInputAntd type="textarea" label="Notes" name="notes" />
					</form>
				</FormProvider>
			</AntdModal>
			<NearbyEmployeesModal
				filterEmployeeType={postData?.type || ""}
				modalHandler={(close: boolean) => setIsModalOpen(close)}
				open={isModalOpen}
				location={location}
				reset={handleResetFilterOption}
				canSelect={true}
			/>
		</>
	)
}

export default CreateScheduleModal
