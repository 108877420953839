import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAppDispatch, useAppSelector } from "hooks"

import { FormInputAntd, DateField } from "components"

import {
	saveAdditionalDetailData,
	selectAdditionalDetailSaving,
} from "store/Location/AdditionalDetail/additionalDetail.slice"

import { convertIOSDateToHTMLFormat } from "config/utils"
import { GetLocationAdditionalDetailInterface } from "@type/locations.types"
import {
	addLocationAdditionalDetailSchema,
	LocationAdditionalDetailInitialValues,
} from "Schema/Locations/locationSchema"
import { GLOBAL_DATE_FORMAT } from "config/constants"
import { selectSelectedLocation } from "store/globalState.slice"
import AntdModal from "components/AntdModal"

interface Props {
	open: boolean
	row: GetLocationAdditionalDetailInterface | undefined
	modalHandler: (open: boolean) => void
}

const AdditionalDetailModal: React.FC<Props> = ({ open, modalHandler, row }) => {
	const dispatch = useAppDispatch()
	const isSaving = useAppSelector(selectAdditionalDetailSaving())

	const selected = useAppSelector(selectSelectedLocation)

	const methods = useForm<GetLocationAdditionalDetailInterface>({
		resolver: yupResolver(addLocationAdditionalDetailSchema),
		shouldUnregister: true,
	})

	const {
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { isDirty, isSubmitting },
	} = methods

	const onSubmit = (data: GetLocationAdditionalDetailInterface) =>
		selected &&
		dispatch(
			saveAdditionalDetailData({ ...data, location: selected, _id: row?._id || "" }, () => modalHandler(false)),
		)

	useEffect(() => {
		if (row)
			reset({
				...row,
				issueDate: convertIOSDateToHTMLFormat(row.issueDate, GLOBAL_DATE_FORMAT),
				returnDate: convertIOSDateToHTMLFormat(row.returnDate, GLOBAL_DATE_FORMAT),
				checked: convertIOSDateToHTMLFormat(row.checked, GLOBAL_DATE_FORMAT),
				nextCheck: convertIOSDateToHTMLFormat(row.nextCheck, GLOBAL_DATE_FORMAT),
			})
		else reset(LocationAdditionalDetailInitialValues)
	}, [row?._id])

	return (
		<AntdModal
			open={open}
			title={"Additional Details"}
			onCancel={() => modalHandler(false)}
			okText={row ? "Update" : "Save"}
			okButtonProps={{ loading: isSaving, disabled: !isDirty || isSubmitting }}
			onOk={handleSubmit(onSubmit)}
		>
			<FormProvider {...methods}>
				<form>
					<div className="grid grid-cols-3 gap-y-3 gap-x-6">
						<FormInputAntd type="text" label="Key" name="key" />
						<FormInputAntd type="text" label="Pouch" name="pouch" />
						<FormInputAntd type="text" name="description" label="Description" />
						<FormInputAntd name="position" label="Position" type="text" />
						<FormInputAntd name="serialNo" label="Serial No" type="text" />
						<FormInputAntd name="keyType" label="Key Type" type="text" />
						<DateField label={"Issue Date"} setValue={setValue} watch={watch} name="issueDate" />
						<DateField label={"Return Date"} setValue={setValue} watch={watch} name="returnDate" />
						<DateField label={"Checked"} setValue={setValue} watch={watch} name="checked" />
						<DateField label={"Next Check"} setValue={setValue} watch={watch} name="nextCheck" />
						<div className="col-span-2">
							<FormInputAntd name="notes" label="Notes" type="textarea" />
						</div>
					</div>
				</form>
			</FormProvider>
		</AntdModal>
	)
}

export default AdditionalDetailModal
