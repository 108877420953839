import { AuditTrailTableWrapper, Tabs, Tab } from "components"
import useAppDispatch from "hooks/useAppDispatch"
import { getAuditTrail } from "store/AuditTrail/auditTrail.slice"
import { Entities } from "types/auditTrail.types"
import React, { useEffect } from "react"
import { caseConverters, getMomentZInstanceOfDate } from "config/utils"
import { DATE_FORMATS } from "config/constants"
import AntdModal from "components/AntdModal"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	scheduleId: string
}

const convertFieldWrtType = (value: string) => {
	return getMomentZInstanceOfDate(value).format(DATE_FORMATS.SHIFT_EXPECTED_TIME_FORMAT)
}

const renderStringForTable = (val: string) => <div className=" w-[180px] text-left">{val}</div>
const renderDateForTable = (val: string) => <div className=" w-28 text-left">{val}</div>

const tableHeaders = [
	{
		label: "Start Time",
		key: "startTime",
		render: ({ row, key }: any) => renderDateForTable(convertFieldWrtType(row[key])),
	},
	{
		label: "Finish Time",
		key: "finishTime",
		render: ({ row, key }: any) => renderDateForTable(convertFieldWrtType(row[key])),
	},
	{
		label: "Site Name",
		key: "location",
		render: ({ row, key }: any) => renderStringForTable(row[key]),
	},
	{ label: "Employee", key: "employee", render: ({ row, key }: any) => renderStringForTable(row[key]) },
	{ label: "Status", key: "status" },
	{ label: "User", key: "user" },
	{
		label: "Changes",
		key: "changes",
		render: ({ row, key }: any) => <>{row[key]}</>,
	},
	{
		label: "Comments",
		key: "notes",
		render: ({ row, key }: any) => (
			<div
				className={`min-w-fit max-w-[450px] text-left ${
					row.updatedFields?.includes(key) ? "bg-green-200" : ""
				}`}
			>
				{row[key]}
			</div>
		),
	},
	{ label: "Type", key: "type" },
	{
		label: "Action",
		key: "method",
		render: ({ row, key }: any) => <>{row.isDeleted ? "Deleted" : caseConverters.titleCase(row[key])}</>,
	},
	// {
	// 	label: "Bypassed SIA",
	// 	key: "bypassSiaVerification",
	// 	render: ({ row, key }: any) => <div>{row[key] ? "Yes" : "No"}</div>,
	// },
	{
		label: "Event Time",
		key: "createdAt",
		render: ({ row, key }: any) => renderDateForTable(convertFieldWrtType(row[key])),
	},
]

const ScheduleAuditTrail: React.FC<Props> = ({ open, modalHandler, scheduleId }) => {
	const dispatch = useAppDispatch()
	const entityName = Entities.SCHEDULES

	useEffect(() => {
		if (scheduleId) dispatch(getAuditTrail(scheduleId, entityName))
	}, [scheduleId])

	return (
		<AntdModal title="Audit" open={open} width={1500} onCancel={() => modalHandler(false)} footer={null}>
			<Tabs initialTab={0}>
				<Tab title="Audit Logs" hasHeader>
					<div className="mt-2">
						<AuditTrailTableWrapper id={scheduleId} name={Entities.SCHEDULES} tableHeaders={tableHeaders} />
					</div>
				</Tab>
			</Tabs>
		</AntdModal>
	)
}

export default ScheduleAuditTrail
