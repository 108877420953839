import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form"
import { AntdSelect1, FormInputAntd } from "components"
import { AllocateScheduleInterface, FilterEmployeeFromSupplierOrBranchInterface } from "@type/schedules.types"
import { AllocationSchedulesByLocationValidationSchema } from "Schema"
import { useEffect, useState } from "react"
import useAppSelector from "hooks/useAppSelector"
import { getBranches, selectBranchesMappedAsOptions } from "store/Branches/branch.slice"
import { getSDSuppliers, selectSDSuppliersDropdownList } from "store/StaticData/SDSuppliers.slice"
import useAppDispatch from "hooks/useAppDispatch"
import { filterEmployeeByBranchOrSupplierName } from "store/Employee/detail.slice"
import { allocatingSchedule, selectScheduleEmployeeAllocation } from "store/Schedules/schedules.slice"
import { ShiftInterface } from "@type/workMonitor.types"
import { Switch } from "antd"
import { Permissions } from "config/constants"
import { AllowedTo } from "react-abac"
import AntdModal from "components/AntdModal"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	showInfo: { [key: string]: string }
	selectedRow: ShiftInterface | null
}

const AllocateShiftModal: React.FC<Props> = ({ open, modalHandler, showInfo, selectedRow }) => {
	const methods = useForm<AllocateScheduleInterface>({
		resolver: yupResolver(AllocationSchedulesByLocationValidationSchema),
		shouldUnregister: true,
	})

	const { handleSubmit, setValue, watch } = methods

	const dispatch = useAppDispatch()
	//Selectors
	const branchList = useAppSelector(selectBranchesMappedAsOptions())
	const supplierList = useAppSelector(selectSDSuppliersDropdownList)
	const isEmployeeAllocation = useAppSelector(selectScheduleEmployeeAllocation())

	//States
	const [filterOptions, setFilterOptions] = useState<FilterEmployeeFromSupplierOrBranchInterface>({
		filterId: "",
		filterFrom: "",
	})

	const filterEmployeeListByBranchOrSupplierName = useAppSelector(
		filterEmployeeByBranchOrSupplierName(filterOptions.filterId, filterOptions.filterFrom),
	)
	useEffect(() => {
		dispatch(getBranches(false))
		dispatch(getSDSuppliers(false))
		setValue("employee", "")
		setValue("bypassSiaVerification", false)
		setValue("bypassMaxConsecutiveWorkdaysVerification", false)
	}, [])

	const onSubmit = (data: AllocateScheduleInterface) => {
		const { employee, notes, bypassSiaVerification, bypassMaxConsecutiveWorkdaysVerification } = data

		if (selectedRow?.scheduleId) {
			const payload = {
				employee,
				bypassSiaVerification,
				bypassMaxConsecutiveWorkdaysVerification,
				ids: [selectedRow.scheduleId],
				notes: notes?.trim(),
			}

			dispatch(allocatingSchedule(payload, () => modalHandler(false)))
		}
	}

	return (
		<AntdModal
			open={open}
			width={600}
			title={"Allocate Schedules"}
			onCancel={() => modalHandler(false)}
			onOk={handleSubmit(onSubmit)}
			okText="Save"
			okButtonProps={{ loading: isEmployeeAllocation }}
		>
			<FormProvider {...methods}>
				<form>
					<div className="flex flex-col text-sm font-semibold text-dark">
						<span>Location: {showInfo.location}</span>
						<span> Posts: {showInfo?.post}</span>
					</div>
					<div className="grid grid-cols-2 gap-2">
						<AntdSelect1
							value={watch("branch")}
							onChange={value => {
								setValue("supplier", "")
								setValue("branch", value)
								setFilterOptions({ filterId: value, filterFrom: "branch" })
							}}
							label="Branch"
							options={[{ label: "Select Branch", value: "Select Branch" }, ...branchList]?.map(
								({ label, value }) => ({ label, value }),
							)}
						/>
						<AntdSelect1
							value={watch("supplier")}
							onChange={value => {
								setValue("branch", "Select Branch")
								setValue("supplier", value)
								setFilterOptions({ filterId: value, filterFrom: "supplier" })
							}}
							label="Supplier"
							options={supplierList?.map(({ label, value }) => ({ label, value }))}
						/>

						<AntdSelect1
							label="Employee"
							value={watch("employee")}
							onChange={value => {
								if (value) {
									setValue("employee", value)
									if (!value) {
										setValue("bypassSiaVerification", true)
										setValue("bypassMaxConsecutiveWorkdaysVerification", true)
									} else {
										setValue("bypassSiaVerification", false)
										setValue("bypassMaxConsecutiveWorkdaysVerification", false)
									}
								}
							}}
							options={[
								{ label: "Select Employee", value: "" },
								...filterEmployeeListByBranchOrSupplierName,
							]?.map(({ label, value }) => ({ label, value }))}
						/>

						<div>
							<AllowedTo perform={Permissions.BYPASS_SIA_VALIDATION}>
								<div
									className="mt-6 mb-2 w-fit cursor-pointer"
									onClick={() => setValue("bypassSiaVerification", !watch("bypassSiaVerification"))}
								>
									<label className="mr-2 cursor-pointer text-md font-normal">
										Bypass SIA verification
									</label>
									<Switch checked={watch("bypassSiaVerification")} />
								</div>
							</AllowedTo>

							<AllowedTo perform={Permissions.BYPASS_MAX_CONSECUTIVE_WORKDAYS_VALIDATION}>
								<div
									className="mb-2 w-fit cursor-pointer"
									onClick={() =>
										setValue(
											"bypassMaxConsecutiveWorkdaysVerification",
											!watch("bypassMaxConsecutiveWorkdaysVerification"),
										)
									}
								>
									<label className="mr-2 cursor-pointer text-md font-normal">
										Bypass Max Consecutive Workdays verification
									</label>
									<Switch checked={watch("bypassMaxConsecutiveWorkdaysVerification")} />
								</div>
							</AllowedTo>
						</div>

						<FormInputAntd type="textarea" label="Notes" name="notes" />
					</div>
				</form>
			</FormProvider>
		</AntdModal>
	)
}

export default AllocateShiftModal
