import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "hooks"
import { Input } from "components"
import { Moment } from "moment"
import { DatePicker } from "antd"
import { DATE_FORMATS, ScheduleTypesEnum } from "config/constants"
import { CreateScheduleByEmployeeFormInterface } from "./CreateEmployeeScheduleModal"
import { InActiveSchedule } from "@type/locations.types"
import { getMomentZInstanceOfDate } from "config/utils"
import { createInactiveSchedule, isLocationPostsSaving } from "store/Location/locationPosts.slice"
import { TableCell } from "./EmployeeTable"
import AntdModal from "components/AntdModal"

interface Props {
	open: boolean
	modalHandler: (val: boolean) => void
	createScheduleModalData: CreateScheduleByEmployeeFormInterface | null
	resetTable: () => void
	selectedCell: TableCell[]
}

const CreateRecurringEmployeeScheduleModal: React.FC<Props> = ({
	open,
	modalHandler,
	createScheduleModalData,
	resetTable,
	selectedCell,
}) => {
	const dispatch = useAppDispatch()
	const isInActiveScheduleLoading = useAppSelector(isLocationPostsSaving())
	const [date, setDate] = useState<Moment | null>(null)
	const [isFourOnFourOff, setFourOnFourOff] = useState<boolean>(false)
	const [weeksInterval, setWeeksInterval] = useState<string>("1")
	const [shouldCreateForever, setShouldCreateForever] = useState<boolean>(true)

	useEffect(() => {
		if (open) {
			setDate(null)
			setWeeksInterval("1")
			setFourOnFourOff(false)
		}
	}, [open])

	const toggleFourOnFourOff = () => setFourOnFourOff(prev => !prev)

	const onSubmitHandler = () => {
		let payload: InActiveSchedule[] = []
		let postId = ""

		if (createScheduleModalData) {
			const {
				startTime = "",
				finishTime = "",
				date: cellDate = "",
				post = "",
				location = "",
				employee = "",
				type = ScheduleTypesEnum.DUTY,
			} = createScheduleModalData

			postId = post

			payload.push({
				location,
				employee,
				startTime,
				finishTime,
				date: cellDate,
				type,
				from: getMomentZInstanceOfDate(cellDate, DATE_FORMATS.DD_MM_YYYY).startOf("day").utc().toISOString(),
				until: date ? date.endOf("day").utc().toISOString() : "",
				weeksInterval: isFourOnFourOff ? 0 : +weeksInterval,
				isFourOnFourOff,
			})
		} else {
			payload = selectedCell.map(({ startTime = "", finishTime = "", date: cellDate = "", ...rest }) => {
				const startTimeMoment = getMomentZInstanceOfDate(startTime).add(1, "w").utc().toISOString()
				const finishTimeMoment = getMomentZInstanceOfDate(finishTime).add(1, "w").utc().toISOString()

				const recurStartDate = isFourOnFourOff
					? getMomentZInstanceOfDate(cellDate, DATE_FORMATS.DD_MM_YYYY)
							.add(4, "days")
							.format(DATE_FORMATS.DD_MM_YYYY)
					: getMomentZInstanceOfDate(cellDate, DATE_FORMATS.DD_MM_YYYY)
							.add(1, "w")
							.format(DATE_FORMATS.DD_MM_YYYY)

				return {
					location: rest.location?._id || "",
					...(rest?.employee && { employee: rest?.employee }),
					startTime: startTimeMoment,
					finishTime: finishTimeMoment,
					date: recurStartDate,
					type: ScheduleTypesEnum.DUTY,
					post: (rest.post as any)?._id,
					from: getMomentZInstanceOfDate(recurStartDate, DATE_FORMATS.DD_MM_YYYY)
						.startOf("day")
						.utc()
						.toISOString(),
					until: date ? date.endOf("day").utc().toISOString() : "",
					weeksInterval: isFourOnFourOff ? 0 : +weeksInterval,
					isFourOnFourOff: isFourOnFourOff,
				}
			})
		}

		dispatch(
			createInactiveSchedule({ postId, inActiveSchedule: payload }, () => {
				setShouldCreateForever(false)
				modalHandler(false)
				resetTable()
			}),
		)
	}

	return (
		<AntdModal
			open={open}
			title={"Recurring Schedules"}
			onCancel={() => {
				modalHandler(false)
				!!createScheduleModalData
			}}
			okText="Save"
			width={400}
			okButtonProps={{
				disabled: (!weeksInterval && !isFourOnFourOff) || (!date && !shouldCreateForever),
				loading: isInActiveScheduleLoading,
			}}
			onOk={onSubmitHandler}
		>
			<div className="mt-3 mb-4 flex h-auto w-fit flex-col items-start justify-center space-y-6">
				<div>
					<span className="text-lg font-medium">Interval Scheme</span>
					<div className="flex items-center justify-center space-y-2 space-x-10">
						<div className="flex w-fit cursor-pointer flex-row-reverse space-x-2 space-x-reverse">
							<Input
								type="checkbox"
								label="4 On 4 Off"
								className="w-fit p-0"
								checked={isFourOnFourOff}
								onChange={toggleFourOnFourOff}
							/>
						</div>
						<span className="text-lg font-medium">Or</span>
						<div className="flex items-center justify-center space-x-1">
							<span>Every</span>
							<Input
								type="text"
								inputMode="numeric"
								placeholder="1"
								disabled={isFourOnFourOff}
								className="w-14 rounded-md py-0 pl-2"
								value={isFourOnFourOff ? "" : weeksInterval}
								onChange={e =>
									e.target.value && (isNaN(Number(e.target.value)) || Number(e.target.value) < 1)
										? setWeeksInterval("1")
										: setWeeksInterval(e.target.value)
								}
							/>
							<span>{"week(s)"}</span>
						</div>
					</div>
				</div>
				<div className="w-full">
					<span className="text-lg font-medium">Until</span>
					<div className="flex items-center justify-between">
						<div className="flex cursor-pointer flex-row-reverse space-x-2 space-x-reverse">
							<Input
								checked={shouldCreateForever}
								className="w-fit p-0"
								type="checkBox"
								label="Forever"
								onChange={e => {
									if (date) setDate(null)
									setShouldCreateForever(e.target.checked)
								}}
							/>
						</div>
						<span className="ml-4 text-lg font-medium">Or</span>
						<div className="space-x-2">
							<DatePicker
								className={"h-9 rounded-md"}
								allowClear={true}
								format={DATE_FORMATS.DD_MM_YYYY}
								onChange={e => {
									setDate(e)
									setShouldCreateForever(false)
								}}
								value={date || null}
							/>
						</div>
					</div>
				</div>
			</div>
		</AntdModal>
	)
}

export default CreateRecurringEmployeeScheduleModal
