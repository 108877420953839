import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit"
import { AppThunk, RootState } from "store"

import { locationContracts } from "services"
import { GetLocationContractsInterface, CreateLocationContractsInterface } from "@type/locations.types"

import { generateContractLabel, updateState, weekDates } from "config/utils"
import { DATE_FORMATS } from "config/constants"
import moment from "moment"
import { DuplicateContractInterface } from "@pages/locations/Contracts/DuplicateContractModal"

export interface locationContractsStateInterface {
	list: GetLocationContractsInterface[]
	loading: boolean
	saving: boolean
}

const initialState: locationContractsStateInterface = {
	list: [],
	loading: false,
	saving: false,
}

const locationContractsSlice = createSlice({
	name: "locationContracts",
	initialState,
	reducers: {
		fetchingLocationContracts: contracts => {
			contracts.loading = true
		},
		locationContractsFetched: (contracts, action: PayloadAction<GetLocationContractsInterface[]>) => {
			contracts.loading = false
			contracts.list = action.payload
		},
		locationContractsFetchingFailed: contracts => {
			contracts.loading = false
		},
		savingLocationContracts: locationContractsData => {
			locationContractsData.saving = true
		},
		locationContractsSaved: (contracts, action: PayloadAction<GetLocationContractsInterface>) => {
			contracts.saving = false
			const [updatedValue] = updateState(contracts.list, action.payload, "_id")
			contracts.list = updatedValue
		},
		locationContractsSavingFailed: contracts => {
			contracts.saving = false
		},
		clearContractList: state => {
			state.list = []
		},
	},
})
//REDUCER
export default locationContractsSlice.reducer
//ACTIONS
export const {
	fetchingLocationContracts,
	locationContractsFetched,
	locationContractsFetchingFailed,
	savingLocationContracts,
	locationContractsSaved,
	locationContractsSavingFailed,
	clearContractList,
} = locationContractsSlice.actions

const getLocationContractsByLocationId =
	(LocationId: string): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingLocationContracts())
			const { data: locationContractsResponse } = await locationContracts.getContractsByLocationId(LocationId)
			dispatch(locationContractsFetched(locationContractsResponse))
		} catch (error) {
			dispatch(locationContractsFetchingFailed())
		}
	}
const saveLocationContracts =
	(locationContractsData: Partial<GetLocationContractsInterface>): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingLocationContracts())
			if (locationContractsData._id)
				data = await locationContracts.updateLocationContracts(locationContractsData._id, locationContractsData)
			else
				data = await locationContracts.createLocationContracts(
					locationContractsData as CreateLocationContractsInterface,
				)
			const { data: locationContractsResponse } = data
			dispatch(locationContractsSaved(locationContractsResponse))
		} catch (error) {
			dispatch(locationContractsSavingFailed())
		}
	}

const duplicateLocationContract =
	(payload: DuplicateContractInterface, cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingLocationContracts())
			const { data: duplicateContractResponse } = await locationContracts.duplicateLocationContract(payload)
			dispatch(locationContractsSaved(duplicateContractResponse))
			cb?.()
		} catch (error) {
			dispatch(locationContractsSavingFailed())
		}
	}
export { getLocationContractsByLocationId, saveLocationContracts, duplicateLocationContract }
//SELECTORS
const selectLocationContractsState = (state: RootState) => state.location.contracts
const isLocationContractsLoading = () => (state: RootState) => selectLocationContractsState(state).loading
const isLocationContractsSaving = () => (state: RootState) => selectLocationContractsState(state).saving
const selectLocationContractsData = () => (state: RootState) => selectLocationContractsState(state).list

const selectLocationContractsDataDropDown = (locationId: string) =>
	createSelector(
		(state: RootState) => state.location.contracts.list,
		contractList => {
			if (!contractList) return []
			const sortedContractList = contractList
				.filter(contract => contract.location == locationId)
				.sort((a, b) => new Date(b?.startDate).valueOf() - new Date(a?.startDate).valueOf())
				.map(({ _id, startDate, finishDate }) => {
					return {
						label: generateContractLabel(startDate, finishDate),
						finishDate: finishDate || "",
						value: _id,
					}
				})
			return [...sortedContractList]
		},
	)

const selectContractById = (contractId: string | null | undefined) => (state: RootState) =>
	selectLocationContractsData()(state)?.find(contract => contract._id === contractId)
const selectContractByLocationId = (locationId: string | null) => (state: RootState) =>
	selectLocationContractsData()(state)?.find(contract => contract.location === locationId)

const selectContractByIdForWeeks = (contractId: string | null) =>
	createSelector(
		(state: RootState) => state.location.contracts.list,
		contractList => {
			const res = contractList?.find(contract => contract._id === contractId)

			if (res && !res.finishDate) {
				const finishDate = moment().add(1, "year").format(DATE_FORMATS.YYYY_MM_DD_T_HH_MM_SS)
				return [...weekDates(res?.startDate, finishDate.toString()).map(week => ({ label: week, value: week }))]
			}
			return (
				(res && [...weekDates(res?.startDate, res?.finishDate).map(week => ({ label: week, value: week }))]) ||
				[]
			)
		},
	)

export {
	selectContractByIdForWeeks,
	selectLocationContractsData,
	selectLocationContractsState,
	isLocationContractsSaving,
	isLocationContractsLoading,
	selectLocationContractsDataDropDown,
	selectContractById,
	selectContractByLocationId,
}
