import { DatePicker } from "antd"
import styles from "../../../src/antd-datePicker.module.css"
import { useState } from "react"
import moment from "moment"
import { duplicatePayroll, selectPayrollSaving } from "store/Account/payroll.slice"
import useAppDispatch from "hooks/useAppDispatch"
import useAppSelector from "hooks/useAppSelector"
import AntdModal from "components/AntdModal"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	selectedMonth: string
}

const DuplicatePayrollModal: React.FC<Props> = ({ open, modalHandler, selectedMonth }) => {
	const dispatch = useAppDispatch()
	const [selectedDate, setSelectedDate] = useState(moment().startOf("day").startOf("month"))
	const duplicatePayrollLoading = useAppSelector(selectPayrollSaving)

	const submitDuplicatePayroll = () => {
		dispatch(
			duplicatePayroll({ duplicateFromMonth: selectedMonth, duplicateToMonth: selectedDate?.toISOString() }, () =>
				modalHandler(false),
			),
		)
	}

	return (
		<AntdModal
			open={open}
			onCancel={() => modalHandler(false)}
			title={"Duplicate Payroll"}
			okText={"Duplicate"}
			width={400}
			okButtonProps={{ loading: duplicatePayrollLoading }}
			onOk={submitDuplicatePayroll}
		>
			<div>
				<div>For Month</div>
				<div className="!h-12 !w-84">
					<DatePicker.MonthPicker
						className={styles.datePickerStyle}
						format={"MM-YYYY"}
						value={selectedDate}
						placeholder={"MM-YYYY"}
						onChange={time => {
							if (time) {
								setSelectedDate(time.startOf("day").startOf("month"))
							}
						}}
					/>
				</div>
			</div>
		</AntdModal>
	)
}

export default DuplicatePayrollModal
