import { PreviousShiftEvent } from "types/workMonitor.types"
import React from "react"
import moment from "moment"
import useAppSelector from "hooks/useAppSelector"
import { selectUserById } from "store/Security/users.slice"
import { Icon, SignedUrlForImage, Tooltip } from "components/index"
import { Image } from "antd-v5"

interface Props {
	events: PreviousShiftEvent
	shiftStatusColor: string
}

const QRCard: React.FC<Props> = ({ events, shiftStatusColor }) => {
	const user = useAppSelector(selectUserById(events?.user?._id ?? events?.user ?? null))

	return (
		<div className="m-2 cursor-pointer rounded-md border-[0.75px] border-dark/20 p-2 font-semibold">
			<div className="flex w-full flex-col items-center justify-center 3xl:flex-row 3xl:justify-between">
				<div className="flex">
					<span className={`h-fit rounded-2 text-white ${shiftStatusColor} px-2 text-sm font-semibold`}>
						PATROL
					</span>
				</div>
				<div className="mt-2 flex items-center space-x-0 sm:flex-col 3xl:flex-row 3xl:space-x-5">
					<span className="col-span-4">
						Due Time:
						{moment(events.dueTime).isValid() ? moment(events.dueTime).format("HH:mm") : events.dueTime}
					</span>
				</div>
			</div>
			<div>
				{!!events.scannedQREvents?.length && (
					<div className="flex flex-col space-y-2">
						{events.scannedQREvents.map(qrEvents => (
							<div className="flex flex-col space-y-2 rounded-md bg-success/20 p-2" key={qrEvents._id}>
								<div className="flex justify-between ">
									<div>{qrEvents.name}</div>
									<div>{`Event Time: ${moment(qrEvents.eventTime).format("HH:mm")} `}</div>
								</div>
								<div className="flex justify-between">
									<div className="flex space-x-1">
										Location:{" "}
										<a
											href={`https://www.google.com/maps?q=${qrEvents.geo.coordinates[0]},${qrEvents.geo.coordinates[1]}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											View on map
										</a>
									</div>
									<span>{`Scanned by: ${user?.firstName} ${user?.lastName}`}</span>
								</div>

								<div className={`grid grid-cols-4 gap-x-3 p-2 `}>
									<Image.PreviewGroup>
										{qrEvents.images?.map((imageUrl, index) => {
											return (
												<SignedUrlForImage path={imageUrl} key={`loadedImage_${index}`}>
													{(image: string) => <Image src={image} />}
												</SignedUrlForImage>
											)
										})}
									</Image.PreviewGroup>
								</div>
							</div>
						))}
					</div>
				)}
			</div>

			{!!events.missingQREvents?.length && (
				<div className="w-fit">
					{events.missingQREvents.map((missingQR, index) => (
						<div key={index} className="my-1 w-auto space-x-2 rounded-md bg-danger-light/30 p-2">
							<div className="flex justify-between space-x-2">
								<span>{missingQR.name}</span>
								<Tooltip message="Not scanned!">
									<Icon name="info" size={20} color="danger" />
								</Tooltip>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default QRCard
