import React, { useContext, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import useAppDispatch from "hooks/useAppDispatch"
import useAppSelector from "hooks/useAppSelector"
import { yupResolver } from "@hookform/resolvers/yup"
import { AiOutlineClose } from "react-icons/ai"

import { AntdButton, FormInputAntd } from "components"
import CallTimeTable from "./CallTimeTable"

import TimeCapturesContext, { TimeCapturesContextProps } from "./TimeCapturesContext"
import { CreateLocationInventoryCallInterface, NewCall } from "@type/locations.types"
import {
	getLocationInventoryCall,
	isDeletingLocationInventoryCall,
	isLoadingLocationInventoryCall,
	isSavingLocationInventoryCall,
	removeInventoryCalls,
	saveLocationInventoryCalls,
	selectLocationInventoryCallColumnLabels,
	selectLocationInventoryCallListWithLabel,
} from "store/Location/locationInventoryCall.slice"
import { LocationInventoryCallSchema } from "Schema/Locations/locationSchema"
import { CallTimeDaysArray } from "./types"
import { AllowedTo } from "react-abac"
import { Permissions } from "config/constants"

const InventoryCall = () => {
	const dispatch = useAppDispatch()
	const [cellsToRemove, setCellsToRemove] = useState<string[]>([])
	const [cellsToAdd, setCellsToAdd] = useState<NewCall[]>([])
	const isLoading = useAppSelector(isLoadingLocationInventoryCall)
	const isSaving = useAppSelector(isSavingLocationInventoryCall)
	const isDeleting = useAppSelector(isDeletingLocationInventoryCall)
	const inventoryCalls = useAppSelector(selectLocationInventoryCallListWithLabel)
	const columnHeaderLabels = useAppSelector(selectLocationInventoryCallColumnLabels)
	const isLoadingInventoryCalls = useAppSelector(isLoadingLocationInventoryCall)
	const { selectedPost } = useContext(TimeCapturesContext) as TimeCapturesContextProps

	useEffect(() => {
		if (selectedPost && !isSaving) dispatch(getLocationInventoryCall(selectedPost))
	}, [selectedPost, isSaving])

	const methods = useForm<CreateLocationInventoryCallInterface>({
		resolver: yupResolver(LocationInventoryCallSchema),
	})

	const { handleSubmit } = methods

	const handleRemoveCheckCall = async (e: any) => {
		e.preventDefault()
		dispatch(removeInventoryCalls(cellsToRemove, () => setCellsToRemove([])))
	}

	const handleAddCheckCall = async (e: any) => {
		e.preventDefault()
		if (selectedPost) {
			dispatch(
				saveLocationInventoryCalls({ inventoryCalls: cellsToAdd, post: selectedPost }, () => setCellsToAdd([])),
			)
			handleClearTable()
		}
	}

	const handleClearTable = () => {
		setCellsToAdd([])
		setCellsToRemove([])
	}

	const onSubmit = (data: CreateLocationInventoryCallInterface) => {
		dispatch(saveLocationInventoryCalls({ ...data, post: selectedPost as string }))
	}

	return (
		<>
			<CallTimeTable
				data={inventoryCalls}
				rowHeaderKey="day"
				columnHeaderKey="time"
				isLoading={isLoadingInventoryCalls}
				cellsToAdd={cellsToAdd}
				cellsToRemove={cellsToRemove}
				setCellsToAdd={setCellsToAdd}
				setCellsToRemove={setCellsToRemove}
				rowHeaderLabels={CallTimeDaysArray}
				columnHeaderLabels={columnHeaderLabels}
			/>

			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="flex flex-col">
						<div className="my-2 flex w-1/2 justify-between gap-2.5">
							<div className="flex space-x-2">
								<AllowedTo perform={Permissions.UPDATE_LOCATION_TIME_CAPTURE}>
									<AntdButton
										disabled={!cellsToAdd.length || isLoading}
										onClick={handleAddCheckCall}
										loading={isSaving}
									>
										Add Inventory Call
									</AntdButton>
									<AntdButton
										disabled={!cellsToRemove.length || isLoading}
										onClick={handleRemoveCheckCall}
										loading={isDeleting}
									>
										Remove Inventory Call
									</AntdButton>
								</AllowedTo>
							</div>
							<div>
								<AntdButton
									disabled={!cellsToRemove.length && !cellsToAdd.length}
									onClick={handleClearTable}
									icon={<AiOutlineClose />}
								>
									Clear
								</AntdButton>
							</div>
						</div>
						<AllowedTo perform={Permissions.UPDATE_LOCATION_TIME_CAPTURE}>
							<div className="flex flex-col gap-2.5">
								<div className="flex flex-row">
									<FormInputAntd
										type="text"
										label="Add New Inventory Call"
										name="time"
										containerClass="mr-5.5 mt-1.25 w-1/2"
									/>
									<p className=" mt-5.5 self-center text-dark-alt">or</p>
								</div>
								<div className=" flex w-full  items-start">
									<FormInputAntd
										containerClass="mt-1 w-50"
										type="text"
										className=" w-full"
										label="Add New Inventory Call"
										name="hours"
									/>
									<div className="mt-5 flex">
										<span className="flex w-40 items-center justify-center text-dark-alt">
											hours at
										</span>
										<FormInputAntd name="minutes" type="text" />
										<span className="ml-5 w-40 text-dark-alt">minutes past </span>
									</div>
								</div>

								<div className="mb-2 flex gap-2.5">
									<AntdButton htmlType="submit">Create Inventory Call(s)</AntdButton>
								</div>
							</div>
						</AllowedTo>
					</div>
				</form>
			</FormProvider>
		</>
	)
}

export default InventoryCall
