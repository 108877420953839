import React from "react"
import { FormInputAntd } from "."
import { FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { userChangePasswordInitialValues, userChangePasswordSchema } from "Schema/Security/security"
import useAppDispatch from "hooks/useAppDispatch"
import { updatePassword } from "store/Security/users.slice"
import AntdModal from "./AntdModal"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	userId: string | null
	confirmationText?: string
	onConfirmation?: (notes: string) => void
	confirmationAction?: boolean
}
interface ChangePasswordForm {
	newPassword: string
	confirmPassword: string
}
const ConfirmationModal: React.FC<Props> = ({ open, modalHandler, userId }) => {
	const dispatch = useAppDispatch()
	const methods = useForm<ChangePasswordForm>({
		resolver: yupResolver(userChangePasswordSchema),
		mode: "onChange",
	})
	const { handleSubmit, reset } = methods

	const onSubmit: SubmitHandler<ChangePasswordForm> = data => {
		if (userId) dispatch(updatePassword(userId, data))
		reset(userChangePasswordInitialValues)
		modalHandler(false)
	}

	return (
		<AntdModal
			title={"Change Password"}
			open={open}
			onCancel={() => modalHandler(false)}
			okText="Submit"
			onOk={handleSubmit(onSubmit)}
		>
			<FormProvider {...methods}>
				<form>
					<div className="mt-5 mb-10">
						<FormInputAntd containerClass="mb-5" label="New Password" name="newPassword" type="password" />
						<FormInputAntd label="Confirm New Password" name="confirmPassword" type="password" />
					</div>
				</form>
			</FormProvider>
		</AntdModal>
	)
}

export default ConfirmationModal
