import { DatePicker } from "antd"
import { Input } from "components"
import { DATE_FORMATS, ScheduleTypesEnum } from "config/constants"
import { useContext, useEffect, useMemo, useState } from "react"
import { Moment } from "moment"
import useAppSelector from "hooks/useAppSelector"
import EmployeeRetainContext, { EmployeeRetainContextProps } from "pages/schedules/Location/CreateScheduleRetainContext"
import { Payload, sortSchedulesByRowColumn } from "pages/schedules/Location/CreateScheduleModal"
import { AddDateIfFinishTimeIsGreater, convertDateTimeStringToIsoUtc, getMomentZInstanceOfDate } from "config/utils"
import { selectScheduleSaving } from "store/Schedules/schedules.slice"
import useAppDispatch from "hooks/useAppDispatch"
import { selectSelectedLocation } from "store/globalState.slice"
import { SelectedSchedules } from "@type/schedules.types"
import { createInactiveSchedule, isLocationPostsSaving } from "store/Location/locationPosts.slice"
import { InActiveSchedule } from "@type/locations.types"
import { ScheduleStatus } from "pages/schedules/Constants/schedulesConstants"
import AntdModal from "./AntdModal"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	selectedSchedule: SelectedSchedules
	resetTable: () => void
}

export const extractPayloadForRecurringScheduleFromTable = (
	recurringData: { post: string; date: string }[],
	startTime: string,
	finishTime: string,
) => {
	const payload: Payload[] = []
	recurringData.forEach(tableCell => {
		const starTimeMoment = convertDateTimeStringToIsoUtc(`${tableCell.date} ${startTime}`)
		let finishTimeMoment = convertDateTimeStringToIsoUtc(`${tableCell.date} ${finishTime}`)

		finishTimeMoment = AddDateIfFinishTimeIsGreater(starTimeMoment, finishTimeMoment)

		// If postAlreadyExists
		const samePostIndex = payload.findIndex(scheduleData => scheduleData.postId === tableCell.post)

		// Append the same post
		if (samePostIndex >= 0) {
			payload[samePostIndex].dates.push({ startTime: starTimeMoment, finishTime: finishTimeMoment })
		}
		// else create new post
		else {
			payload.push({
				postId: tableCell.post,
				dates: [{ startTime: starTimeMoment, finishTime: finishTimeMoment }],
			})
		}
	})

	return payload
}

const RecurringScheduleModal: React.FC<Props> = ({
	open,
	modalHandler,
	resetTable = () => undefined,
	selectedSchedule,
}) => {
	const dispatch = useAppDispatch()
	const isScheduleSaving = useAppSelector(selectScheduleSaving())
	const selected = useAppSelector(selectSelectedLocation)
	const { createScheduleModalData } = useContext(EmployeeRetainContext) as EmployeeRetainContextProps
	const isPostSaving = useAppSelector(isLocationPostsSaving())

	const [date, setDate] = useState<Moment | null>(null)
	const [isFourOnFourOff, setFourOnFourOff] = useState<boolean>(false)
	const [weeksInterval, setWeeksInterval] = useState<string>("1")
	const [shouldCreateForever, setShouldCreateForever] = useState<boolean>(true)
	useEffect(() => {
		if (open) {
			setDate(null)
			setWeeksInterval("1")
			setFourOnFourOff(false)
		}
	}, [open])

	const toggleFourOnFourOff = () => setFourOnFourOff(prev => !prev)

	const onSubmit = () => {
		let payload: InActiveSchedule[] = []
		const sortedSchedules = sortSchedulesByRowColumn(selectedSchedule)
		let postId = ""
		if (!createScheduleModalData) {
			payload = Object.values(selectedSchedule)
				.filter(item => item?._id && item.status !== ScheduleStatus.INACTIVE)
				.map(item => {
					const startTimeMoment = getMomentZInstanceOfDate(new Date(item?.startTime as string))
						.add(1, "w")
						.utc()
						.toISOString()
					const finishTimeMoment = getMomentZInstanceOfDate(new Date(item?.finishTime as string))
						.add(1, "w")
						.utc()
						.toISOString()
					const recurStartDate = isFourOnFourOff
						? getMomentZInstanceOfDate(item?.date, DATE_FORMATS.DD_MM_YYYY)
								.add(4, "days")
								.format(DATE_FORMATS.DD_MM_YYYY)
						: getMomentZInstanceOfDate(item?.date, DATE_FORMATS.DD_MM_YYYY)
								.add(1, "w")
								.format(DATE_FORMATS.DD_MM_YYYY)
					return {
						location: selected,
						...(item?.employee && { employee: item?.employee }),
						startTime: startTimeMoment,
						finishTime: finishTimeMoment,
						date: recurStartDate,
						type: ScheduleTypesEnum.DUTY,
						post: item?.post,
						from: getMomentZInstanceOfDate(recurStartDate, DATE_FORMATS.DD_MM_YYYY)
							.startOf("day")
							.utc()
							.toISOString(),
						until: date ? date.endOf("day").utc().toISOString() : "",
						weeksInterval: isFourOnFourOff ? 0 : +weeksInterval,
						isFourOnFourOff: isFourOnFourOff,
					}
				})
		} else {
			postId = sortedSchedules[0].split("_")[0]
			const schedulesToProcess = isFourOnFourOff ? [sortedSchedules[0]] : sortedSchedules
			schedulesToProcess.forEach(scheduleCellData => {
				const scheduleDate = scheduleCellData.split("_")[1]

				const starTimeMoment = convertDateTimeStringToIsoUtc(
					`${scheduleDate} ${createScheduleModalData?.startTime}`,
				)
				let finishTimeMoment = convertDateTimeStringToIsoUtc(
					`${scheduleDate} ${createScheduleModalData?.finishTime}`,
				)

				finishTimeMoment = AddDateIfFinishTimeIsGreater(starTimeMoment, finishTimeMoment)

				payload.push({
					location: selected,
					...(createScheduleModalData?.employee && { employee: createScheduleModalData?.employee }),
					startTime: starTimeMoment,
					finishTime: finishTimeMoment,
					date: scheduleDate,
					type: createScheduleModalData?.type,
					from: getMomentZInstanceOfDate(scheduleDate, DATE_FORMATS.DD_MM_YYYY)
						.startOf("day")
						.utc()
						.toISOString(),
					until: date ? date.endOf("day").utc().toISOString() : "",
					weeksInterval: isFourOnFourOff ? 0 : +weeksInterval,
					isFourOnFourOff,
				})
			})
		}

		dispatch(
			createInactiveSchedule({ postId, inActiveSchedule: payload }, () => {
				setShouldCreateForever(false)
				resetTable()
			}),
		)
	}

	const selectedScheduleContainSinglePostOnly = useMemo(() => {
		const sortedSchedules = sortSchedulesByRowColumn(selectedSchedule)
		const containsSinglePostId = new Set<string>("")
		sortedSchedules.forEach(scheduleCellData => {
			const [postId] = scheduleCellData.split("_")
			containsSinglePostId.add(postId)
		})

		return !(containsSinglePostId.size > 1)
	}, [selectedSchedule])

	return (
		<AntdModal
			open={open}
			title={"Recurring Schedules"}
			onCancel={() => {
				modalHandler(false)
				!!createScheduleModalData
			}}
			okText="Save"
			onOk={onSubmit}
			okButtonProps={{
				loading: isScheduleSaving || isPostSaving,
				disabled: (!weeksInterval && !isFourOnFourOff) || (!date && !shouldCreateForever),
			}}
			cancelText="Go Back"
			width={450}
		>
			<div className="mt-4 mb-4">
				<div className="mb-2 w-full">
					<span className="text-lg font-medium">Interval Scheme</span>
					<div className="flex items-center justify-between">
						<div className="flex cursor-pointer flex-row-reverse space-x-2 space-x-reverse">
							<Input
								type="checkbox"
								label="4 On 4 Off"
								className="w-fit p-0"
								checked={isFourOnFourOff}
								onChange={toggleFourOnFourOff}
							/>
						</div>
						<span className="text-lg font-medium">Or</span>
						<div className="flex items-center justify-center space-x-1">
							<span>Every</span>
							<Input
								type="text"
								inputMode="numeric"
								placeholder="1"
								disabled={isFourOnFourOff}
								className="w-14 rounded-md py-0 pl-2"
								value={isFourOnFourOff ? "" : weeksInterval}
								onChange={e =>
									e.target.value && (isNaN(Number(e.target.value)) || Number(e.target.value) < 1)
										? setWeeksInterval("1")
										: setWeeksInterval(e.target.value)
								}
							/>
							<span>{"week(s)"}</span>
						</div>
					</div>
				</div>
				<div className="w-full">
					<span className="text-lg font-medium">Until</span>
					<div className="flex items-center justify-between">
						<div className="flex cursor-pointer flex-row-reverse space-x-2 space-x-reverse">
							<Input
								checked={shouldCreateForever}
								className="w-fit p-0"
								type="checkBox"
								label="Forever"
								onChange={e => {
									if (date) setDate(null)
									if (!createScheduleModalData || selectedScheduleContainSinglePostOnly)
										setShouldCreateForever(e.target.checked)
								}}
							/>
						</div>
						<span className="ml-4 text-lg font-medium">Or</span>
						<div className="space-x-2">
							<DatePicker
								className={"h-9 rounded-md"}
								allowClear={true}
								format={DATE_FORMATS.DD_MM_YYYY}
								onChange={e => {
									setDate(e)
									setShouldCreateForever(false)
								}}
								value={date || null}
							/>
						</div>
					</div>
				</div>
			</div>
		</AntdModal>
	)
}
export default RecurringScheduleModal
