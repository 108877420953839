import { Table2 } from "components/index"
import React from "react"
import { TableDataInterface } from "./MasterSheet"
import AntdModal from "components/AntdModal"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	rows: TableDataInterface[]
}

const MasterSheetErrorModal: React.FC<Props> = ({ open, modalHandler, rows }) => {
	const columns = [
		{ label: "#", key: "tempId", className: "text-left" },
		{ label: "Pin", key: "pin", className: "text-left" },
		{ label: "Name", key: "name", className: "text-left" },
		{ label: "Status", key: "payrollTermLabel", className: "text-left" },
		{ label: "Site Name", key: "locationName", className: "text-left" },
		{ label: "Customer Name", key: "customerName", className: "text-left" },
		{ label: "Sin", key: "locationSin", className: "text-left" },
		{ label: "Rate", key: "rate", className: "text-left" },
	]

	return (
		<AntdModal
			open={open}
			onCancel={() => modalHandler(false)}
			width={1000}
			title={"MasterSheet Related Issues"}
			footer={null}
		>
			<div>
				<p className="pt-2.5 pb-4 text-center text-sm font-semibold">
					Following rows are missing in CSV file. Please add them in your CSV file.
				</p>
				<Table2 columns={columns} rows={rows} />
			</div>
		</AntdModal>
	)
}

export default MasterSheetErrorModal
