import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm } from "react-hook-form"
import { TimeField, FormInputAntd } from "components"
import { CreateSchedulesInterface } from "@type/schedules.types"
import { SchedulesByLocationValidationSchema } from "Schema"
import useAppSelector from "hooks/useAppSelector"
import { saveScheduleByEmployee, selectScheduleSaving } from "store/Schedules/schedules.slice"
import useAppDispatch from "hooks/useAppDispatch"
import { TableCell } from "./EmployeeTable"
import { AddDateIfFinishTimeIsGreater, convertDateTimeStringToIsoUtc } from "config/utils"
import AntdModal from "components/AntdModal"

export interface Payload {
	postId: string
	dates: { startTime: string; finishTime: string }[]
}

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	selectedSchedules: TableCell[]
	setSelectedCell: (data: []) => void
}

const UpdateEmployeeScheduleModal: React.FC<Props> = ({ open, modalHandler, selectedSchedules, setSelectedCell }) => {
	const methods = useForm<CreateSchedulesInterface>({
		resolver: yupResolver(SchedulesByLocationValidationSchema),
		shouldUnregister: true,
	})

	const {
		handleSubmit,
		setValue,
		watch,
		formState: { isDirty },
	} = methods

	const dispatch = useAppDispatch()
	const isScheduleSaving = useAppSelector(selectScheduleSaving())

	const onSubmit = (data: CreateSchedulesInterface) => {
		const { startTime, finishTime, notes } = data

		const schedulePayload = selectedSchedules.map(cell => {
			const starTimeMoment = convertDateTimeStringToIsoUtc(`${cell.date} ${startTime}`)
			let finishTimeMoment = convertDateTimeStringToIsoUtc(`${cell.date} ${finishTime}`)

			finishTimeMoment = AddDateIfFinishTimeIsGreater(starTimeMoment, finishTimeMoment)

			return {
				_id: cell._id,
				startTime: starTimeMoment,
				finishTime: finishTimeMoment,
			}
		})

		const payload = { notes, ids: schedulePayload }
		dispatch(
			saveScheduleByEmployee(payload, () => {
				setSelectedCell([])
				modalHandler(false)
			}),
		)
	}

	return (
		<AntdModal
			open={open}
			title={"Update Schedules"}
			onCancel={() => {
				modalHandler(false)
				setValue("employee", "")
			}}
			okText="Save"
			okButtonProps={{ loading: isScheduleSaving, disabled: !isDirty }}
			onOk={handleSubmit(onSubmit)}
		>
			<div className="mb-4">
				<div className="flex items-center justify-between py-2">
					{!!selectedSchedules.length && (
						<span className="text-md font-semibold text-dark">
							Location: {selectedSchedules[0].location?.name || ""}
						</span>
					)}
				</div>
				<FormProvider {...methods}>
					<form className="w-full">
						<div className="grid grid-cols-2 gap-2">
							<TimeField
								label="Start Time"
								watch={watch}
								formValueSetter={setValue}
								name="startTime"
								defaultFocusOn={true}
							/>
							<TimeField label="Finish Time" watch={watch} formValueSetter={setValue} name="finishTime" />

							<div className="col-span-2">
								<FormInputAntd type="textarea" label="Notes" name="notes" />
							</div>
						</div>
					</form>
				</FormProvider>
			</div>
		</AntdModal>
	)
}

export default UpdateEmployeeScheduleModal
