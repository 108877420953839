import React, { useEffect, useState } from "react"
import useAppDispatch from "hooks/useAppDispatch"
import useAppSelector from "hooks/useAppSelector"
import { yupResolver } from "@hookform/resolvers/yup"
import { saveComplaint } from "../../../store/Location/locationComplaints.slice"
import { ShiftInterface } from "@type/workMonitor.types"
import { selectCurrentUser } from "store/auth.slice"
import { FormProvider, useForm } from "react-hook-form"
import { LocationComplaintInterface } from "@type/locations.types"
import { clearMedia, selectLoadingMedia, selectMediaURL, uploadMedia } from "store/Media/media.slice"
import { locationComplaintModalInitialValues, locationComplaintSchema } from "Schema/Locations/locationSchema"
import { DateField, FormInputAntd, FormSelectAntd, Icon, ImageField } from "components"
import { selectEmployeeDropDown } from "../../../store/Employee/detail.slice"
import AntdModal from "components/AntdModal"

export interface CreateLocationComplaintInterface
	extends Omit<LocationComplaintInterface, "_id" | "shift" | "resolvedBy" | "resolvedAt" | "status"> {}

interface Props {
	selectedLocation: string
	open: boolean
	modalHandler: (open: boolean) => void
	selectedRow: ShiftInterface | null
	loading?: boolean
	isForShift?: boolean
	defaultFields?: LocationComplaintInterface
	submitHandler?: (data: any) => void
}

const ComplaintModal: React.FC<Props> = ({
	open,
	modalHandler,
	selectedRow,
	loading = false,
	selectedLocation,
	defaultFields = undefined,
	isForShift = false,
}) => {
	const methods = useForm<CreateLocationComplaintInterface>({
		resolver: yupResolver(locationComplaintSchema),
		defaultValues: locationComplaintModalInitialValues,
	})
	const {
		reset,
		watch,
		setError,
		setValue,
		clearErrors,
		handleSubmit,
		formState: { errors, isSubmitSuccessful },
	} = methods
	const dispatch = useAppDispatch()
	const [userImage, setUserImage] = useState<string[]>([])
	const imageURL = useAppSelector(selectMediaURL())
	const isImageLoading = useAppSelector(selectLoadingMedia())
	const user = useAppSelector(selectCurrentUser())
	const employeesList = useAppSelector(selectEmployeeDropDown())

	useEffect(() => {
		reset(defaultFields ?? { images: [], officer: "" })
		setUserImage([])
		if (user) {
			setValue("reportingOfficer", `${user?.firstName} ${user?.lastName}`)
		}
	}, [selectedRow, isSubmitSuccessful, user, defaultFields])

	useEffect(() => {
		if (imageURL.length) {
			setUserImage(prevState => [...prevState, ...imageURL])
			dispatch(clearMedia())
		}
	}, [imageURL])

	const onSubmit = (data: CreateLocationComplaintInterface) => {
		const payload = {
			...data,
			reportingOfficer: typeof data.reportingOfficer === "string" ? user?._id || "" : data.reportingOfficer._id,
			images: [...data.images, ...userImage],
			location: selectedLocation,
			...(selectedRow?._id && isForShift && { shift: selectedRow?._id }),
		}
		dispatch(saveComplaint(payload))
		modalHandler(false)
	}

	const handleDeleteImage = (imageUrl: string) => {
		setUserImage(prevState => prevState.filter(url => url !== imageUrl))
	}

	return (
		<AntdModal
			open={open}
			title={"Report Complaint"}
			onCancel={() => modalHandler(false)}
			okText="Report"
			width={700}
			onOk={handleSubmit(onSubmit)}
		>
			<div>
				{loading ? (
					<div className="flex h-full w-full items-center justify-center">
						<Icon name="spinner" color="white" size={50} className="mr-3 animate-spin !text-purple" />
					</div>
				) : (
					<FormProvider {...methods}>
						<form>
							<div className="grid grid-cols-3 gap-x-4 gap-y-5 py-3">
								<DateField
									label="Date and Time of Complaint"
									name="date"
									errors={errors}
									clearErrors={clearErrors}
									setValue={setValue}
									watch={watch}
									showTime={true}
								/>
								<FormInputAntd
									label="Reporting Officer Name"
									type="text"
									name="reportingOfficer"
									disabled={true}
								/>

								<FormSelectAntd
									disabled={!employeesList.length}
									label="Officer Name"
									name="officer"
									options={[
										{ label: "Select Officer", value: "" },
										...employeesList.map(item => ({ label: item.name, value: item._id })),
									]}
								/>
							</div>

							<div className="col-span-2 flex transform items-center space-x-2">
								<div className="min-w-fit font-semibold">Complaint Details</div>
								<div className="w-full border-b-[0.5px] border-secondary-light" />
							</div>

							<div className="grid grid-cols-2 gap-x-4 py-3 ">
								<FormInputAntd label="Complaint" name="complaint" type="text" />
								<FormInputAntd label="Complainant" name="complainant" type="text" />
							</div>

							<div className="grid grid-cols-3 gap-x-4 py-3 ">
								<div className={"col-span-2"}>
									<FormInputAntd type="textarea" label="Details of Complaint" name="details" />
								</div>
							</div>

							<div className="flex items-center">
								<ImageField
									name="images"
									containerClass="h-16 w-32"
									loading={isImageLoading}
									image={userImage}
									setError={setError}
									errors={errors}
									uploadMultiple={true}
									clearErrors={clearErrors}
									onImageChange={file => {
										if (!errors["images"]) {
											dispatch(uploadMedia(file))
										}
									}}
									handleDeleteImage={handleDeleteImage}
								/>
							</div>
						</form>
					</FormProvider>
				)}
			</div>
		</AntdModal>
	)
}

export default ComplaintModal
